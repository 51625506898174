/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-key */
/* eslint-disable no-console */

import React from 'react';
import { formatter, validateData } from '../../../constants/CommonMethods';
import { ReactComponent as CloseIcon } from '../../../assets/images/close_icon.svg';

export default function PrintPackagePrice(props: any) {
  const packageIds = validateData(props.packageIdsToPrint)
    ? props.packageIdsToPrint
    : [];
  console.log('packageIds', packageIds);
  const packageDetails = validateData(props.detailsToPrint)
    ? props.detailsToPrint
    : [];
  console.log('packageDetails', packageDetails);

  const printPackagePrice = () => {
    const storeDetails = sessionStorage.getItem('storeDetails') || '';
    const encodestore = Buffer.from(storeDetails, 'base64');
    const decodestore: any = JSON.parse(encodestore.toString('ascii'));
    console.log('inside Print Function', props);
    console.log('decodestore',decodestore);
    return packageIds.map((value: any) => {
      console.log('value', value);

      return  packageDetails.map((data:any,index:any)=>{
        console.log("data",data);
        const rateRegular =(Number(data.regularWeeklyRate) - Number(data.weeklyRate))?.toFixed(2)
        if (decodestore?.state == "CA" || decodestore?.state == "NY"){
          if(value==(data.packagePriceId).toString())
          {
              const costRental = Number(data.totalCost) - Number(data.cashPrice);
            return(
              <div style={{ margin: '0px', fontFamily: 'Arial' }}>
                <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                  <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>
      
                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '14%',marginTop:'19%' }}>
                      <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', display: 'inline-block' }}>
                        {validateData(rateRegular)?
                          rateRegular : ' - '}
                      </h1>
                    </div>
      
                    <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold',marginBottom:'30px' }}>
                    {data.inventoryDetails.map((details:any)=>{
                        return(
                          
                          <p style={{ fontSize: '24px' }}>{validateData(details)&& validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br/></p>
                          )
                      })}
  
                      </div>
      
      
                      <div style={{ display:'flex',justifyContent:'center', marginBottom: '7px',padding:'2px 123px'}}>
                    <table style={{ width: '100%', border: '2px solid #000', borderCollapse: 'collapse' }}>
                      <tbody style={{ fontSize:'20px',width: '100%'}}>
                        <tr>
                          <td rowSpan={2} style={{ borderRight: '2px solid #000', padding: '8px 11px', textAlign: 'center', verticalAlign: 'top',width:'25%' }}>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>TOTAL OF</p>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>PAYMENTS</p>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '5.5px',borderBottom: '2px solid black' }}>                {validateData(data) &&
                              validateData(data.totalCost)
                              ? formatter.format(data.totalCost).replace(/^(\D+)/, '$1 ')
                              : '-'}</p>
                            <p style={{ marginTop: 0, marginBottom: 0,textAlign:'left',fontSize:'14px'  }}>You must pay this amount to own the property if you make all regular payments.</p>
                            <p style={{ marginTop: 0, marginBottom: '25px', textAlign:'left',fontSize:'14px'  }}>(TAX NOT INCLUDED)</p>                            
                            <p style={{ marginTop: 0, marginBottom: '2px',textAlign:'left',fontSize:'14px'  }}>You can buy the property for less under the early purchase option.</p>
  
                          </td>
                          <td style={{ borderRight: '2px solid #000', padding: '8px 11px', textAlign: 'center', verticalAlign: 'top',width:'25%' }}>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>COST OF</p>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>RENTAL</p>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '5.5px',borderBottom: '2px solid black'  }}>{validateData(costRental)
                            ? formatter.format(costRental).replace(/^(\D+)/, '$1 ')
                            : '-'}</p>
                            <p style={{fontSize:'14px',textAlign:'left',marginTop:0}}>Amount over cash price you will pay if you make all regular payments.(TAX NOT INCLUDED)</p>
                          </td>
                          <td colSpan={2} style={{ borderRight: '2px solid #000', padding: '8px 11px', textAlign: 'center', verticalAlign: 'top',width:'50%' }}>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px',textAlign:'left' }}>CASH PRICE</p>
                            <p style={{ fontWeight: 'bold', marginTop: '26px', marginBottom: '5.5px',borderBottom:'2px solid black',width:'34%' }}>{validateData(data) &&
                              validateData(data.cashPrice)
                              ? formatter.format(data.cashPrice).replace(/^(\D+)/, '$1 ')
                              : '-'}</p>
                            <p style={{fontSize:'14px',textAlign:'left',marginTop:0}}>Property available at this price for cash from the lessor. See about your early purchase options rights.(TAX NOT INCLUDED)</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ borderRight: '2px solid #000', borderTop: '2px solid #000', padding: '8px 11px', textAlign: 'center', verticalAlign: 'top' }}>
                            <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 0, marginBottom: '1.5px' }}>AMOUNT OF</p>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '2.5px' }}>EACH PAYMENT</p>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '5.5px',borderBottom: '2px solid black',textAlign:'left'  }}>                {validateData(data) &&
                              validateData(data.weeklyRate)
                              ? formatter.format(data.weeklyRate).replace(/^(\D+)/, '$1 ')
                              : '-'}{' '}</p>
                            <p style={{ marginTop: 0, marginBottom: '2px',fontSize:'14px'  }}>(Tax not included)</p>
                            <p style={{ marginTop: '0', marginBottom: '1.5px',textAlign:'left',fontSize:'14px'  }}>per<u style={{paddingLeft:'22%'}}>Week</u></p>
                          </td>
                          <td style={{ borderRight: '2px solid #000', borderTop: '2px solid #000', padding: '8px 11px', textAlign: 'center', verticalAlign: 'top' }}>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>NUMBER OF</p>
                            {/* <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>OF</p> */}
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '13.5px' }}>PAYMENTS</p>
                            <p style={{ marginTop: '0', marginBottom: '1.5px' }}><span style={{ fontWeight: 'bold' }}>                       {validateData(data) &&
                              validateData(data.weeklyTerm)
                              ? data.weeklyTerm
                              : '-'}{' '}   </span>&nbsp;</p>
                          </td>
                          <td style={{ borderRight: '2px solid #000', borderTop: '2px solid #000', padding: '8px 11px', textAlign: 'center', verticalAlign: 'top' }}>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '1.5px' }}>RENTAL</p>
                            <p style={{ fontWeight: 'bold', marginTop: 0, marginBottom: '13.5px' }}>PERIOD</p>
                            <p style={{ marginTop: '0', marginBottom: '1.5px' }}><span style={{ fontWeight: 'bold' }}>{validateData(data) &&
                              validateData(data.weeklyTerm)
                              ? data.weeklyTerm
                              : '-'}{' '} </span>&nbsp;Weeks</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
      
      
                    <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left',padding:'2px 123px' }}>
                    <div>
                      {data.inventoryDetails.map((details:any)=>{
                        return(
                          <span style={{paddingRight: '20px' }}>
                            {validateData(details)&&validateData(details.inventoryNumber)?details.inventoryNumber:' - '}
                          </span>
                        )
                      })}
                      </div>
                      <p style={{fontWeight:'bold',fontSize:'22px',marginLeft:'20px'}}>MINIMUM RENTAL OBLIGATION:  1 Week</p>
                    </div>
                    {/* <div style={{marginBottom:"600px"}}></div> */}
      
      
                  </div>
      
                </div>
      
              </div>
            )
          }
        }
        else if(decodestore?.state == "AZ" || decodestore?.state == "TX" || decodestore?.state == "FL"){
          if(value==(data.packagePriceId).toString())
          {
            console.log("vvv");
  
            return(
              <div style={{ margin: '0px', fontFamily: 'Arial' }}>
                <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                  <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>
      
                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%',marginTop:'22%',paddingRight:'20px' }}>
                      <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', display: 'inline-block' }}>
                      {validateData(rateRegular)?
                          rateRegular : ' - '}
                      </h1>
                    </div>
      
                    <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold',marginBottom:'30px' }}>
                    {data.inventoryDetails.map((details:any)=>{
                        return(
                          
                          <span style={{ fontSize: '20px' }}>{validateData(details)&& validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br/></span>
                          )
                      })}
  
                      </div>
      
      
                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{border: '1px solid black',padding:'6%',margin:'0% 20%'}}>
      
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br/>
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price<span style={{top:'0',right:'0',position:'absolute'}}>*</span></span>
  
                          <div style={{marginTop:'20px'}}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                              {validateData(data)
                                  && validateData(data.regularWeeklyRate)?
                                  formatter.format(data.regularWeeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                          {validateData(data)
                                  && validateData(data.monthlyRate)?
                                  formatter.format(data.monthlyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                          </span>
                          <div>
      
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                          </div>
                        </div>
      
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                          {validateData(data)
                                  && validateData(data.totalCost)?
                                  formatter.format(data.totalCost).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                          </span>
                          <div>
      
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Total Cost</span>
                          </div>
                        </div>
                      </div>
                    </div>
      
      
                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
      
                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                  && validateData(data.weeklyRate)?
                                  formatter.format(data.weeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                              </span>
      
                          </div>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                        </div>
                      </div>
      
                    </div>
      
      
                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '85px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                  && validateData(data.weeklyTerm)?
                                  data.weeklyTerm : ' - '
                              }
                          </span>
                          <div>
      
                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br/>
                            <span style={{ fontSize: 'larger', fontWeight: '100px'}}>Payments</span>
  
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '35px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                              {validateData(data)
                                  && validateData(data.monthlyTerm)?
                                  data.monthlyTerm : ' - '
                              }
                          </span>
                          <div>
      
                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br/>
                            <span style={{ fontSize: 'larger', fontWeight: '100px'}}>Payments</span>
  
                          </div>
                        </div>
      
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                              {validateData(data)
                                  && validateData(data.cashPrice)?
                                  formatter.format(data.cashPrice).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                          </span>
                          <div>
      
                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Cash Price</span>
                          </div>
                        </div>
                      </div>
                    </div>
      
      
                    <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left' }}>
                    <div style={{ paddingLeft: '54px' }}>
                      {data.inventoryDetails.map((details:any)=>{
                        return(
                          <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                            {validateData(details)&&validateData(details.inventoryNumber)?details.inventoryNumber:' - '}
                          </span>
                        )
                      })}
                      </div>
                    </div>
                    {/* <div style={{marginBottom:"600px"}}></div> */}
      
      
                  </div>
      
                </div>
      
              </div>
            )
          }
        }
        else if (decodestore?.state == "IA") {
          if (value == (data.packagePriceId).toString()) {
            console.log("vvv");
            return (
              <div style={{ margin: '0px', fontFamily: 'Arial' }}>
                <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                  <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>

                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%',marginTop:'22%' }}>
                      <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', marginLeft: '37px', display: 'inline-block' }}>
                      {validateData(rateRegular)?
                          rateRegular : ' - '}
                      </h1>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold' }}>
                      {data.inventoryDetails.map((details: any) => {
                        return (
                          <span style={{ fontSize: '20px' }}>{validateData(details) && validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br /></span>
                        )
                      })}

                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '52px' }}>
                        <div style={{ border: '1px solid black', padding: '6%', margin: '0% 20%' }}>

                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br />
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price</span>

                          <div>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                              {validateData(data)
                                && validateData(data.regularWeeklyRate) ?
                                formatter.format(data.regularWeeklyRate) : ' - '
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '65px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.monthlyRate) ?
                              formatter.format(data.monthlyRate) : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                          </div>
                        </div>

                      </div>
                      {/* <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>

                    </div> */}
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '65px' }}>
                        <div style={{ width: '100%' }}>

                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyRate) ?
                                formatter.format(data.weeklyRate) : ' - '
                              }
                            </span>

                          </div>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                        </div>
                      </div>
                      <div style={{ width: '110%' }}>
                        <div style={{ width: '50%', float: 'left' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px'}}>
                            {validateData(data)
                              && validateData(data.monthlyTerm) ?
                              data.monthlyTerm : ' - '
                            }
                          </span>
                          <div>
                            <span style={{ fontSize: 'larger', fontWeight: '100px',borderTop: '1px solid black' }}>Number of Payments</span>
                          </div>
                        </div>
                        <div style={{ width: '50%', float: 'left', textAlign: 'center' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.totalCost) ?
                              formatter.format(data.totalCost) : ' - '
                            }
                          </span>
                          <div>
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Total Cost</span>
                          </div>
                        </div>
                      </div>

                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '65px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.weeklyTerm) ?
                              data.weeklyTerm : ' - '
                            }
                          </span>
                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Payments</span>

                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.cashPrice) ?
                              formatter.format(data.cashPrice) : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Cash Price</span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}></span>

                          </div>
                        </div>

                      </div>
                      {/* <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>

                    </div> */}
                    </div>
                    <div style={{ width: '600px', textAlign: 'center', float: 'left', fontSize: 'larger', height: '70px', border: '1px solid #000', marginLeft: '60px' }}>
                      <span>This is Consumer Rental Purchase Agreement. You will not own the property until the total cost is paid in full or you exercise your early purchase option as provided for by lowa law</span>
                    </div>

                    <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', marginTop: '10px', float: 'left' }}>
                      <div style={{ paddingLeft: '54px' }}>
                        {data.inventoryDetails.map((details: any) => {
                          return (
                            <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              {validateData(details) && validateData(details.inventoryNumber) ? details.inventoryNumber : ' - '}
                            </span>
                          )
                        })}
                      </div>
                    </div>
                    {/* <div style={{ marginBottom: "600px" }}></div> */}


                  </div>

                </div>

              </div>
            )
          }
        }
        else if (decodestore?.state == "ME" || decodestore?.state == "NC") {
          if (value == (data.packagePriceId).toString()) {
            console.log("vvv");
            return (
              <div style={{ margin: '0px', fontFamily: 'Arial' }}>
                <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                  <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>

                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%',marginTop:'22%' }}>
                      <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', marginLeft: '37px', display: 'inline-block' }}>
                      {validateData(rateRegular)?
                          rateRegular : ' - '}
                      </h1>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold'}}>
                      {data.inventoryDetails.map((details: any) => {
                        return (

                          <span style={{ fontSize: '20px' }}>{validateData(details) && validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br /></span>
                        )
                      })}

                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '45px' }}>
                        <div style={{ border: '1px solid black', padding: '6%', margin: '0% 20%' }}>

                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br />
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price</span>

                          <div>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                              {validateData(data)
                                && validateData(data.regularWeeklyRate) ?
                                formatter.format(data.regularWeeklyRate) : ' - '
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '65px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.monthlyRate) ?
                              formatter.format(data.monthlyRate) : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                          </div>
                        </div>

                      </div>
                    </div>
                    {/* Weekly Price */}
                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '65px' }}>
                        <div style={{ width: '100%' }}>

                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyRate) ?
                                formatter.format(data.weeklyRate) : ' - '
                              }
                            </span>

                          </div>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                        </div>
                      </div>
                      <div style={{ width: '110%' }}>
                        <div style={{ width: '50%', float: 'left' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.monthlyTerm) ?
                              data.monthlyTerm : ' - '
                            }
                          </span>
                          <div>
                            <span style={{ fontSize: 'larger', fontWeight: '100px',borderTop: '1px solid black' }}>Number of Payments</span>
                          </div>
                        </div>
                        <div style={{ width: '50%', float: 'left', textAlign: 'center' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.totalCost) ?
                              formatter.format(data.totalCost) : ' - '
                            }
                          </span>
                          <div>
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Total Cost</span>
                          </div>
                        </div>
                      </div>

                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '65px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.weeklyTerm) ?
                              data.weeklyTerm : ' - '
                            }
                          </span>
                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Payments</span>

                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.cashPrice) ?
                              formatter.format(data.cashPrice) : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Cash Price</span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}></span>

                          </div>
                        </div>

                      </div>
                    </div>
                    <div style={{ width: '560px', textAlign: 'center', float: 'left', fontSize: 'larger', height: '70px', border: '1px solid #000', marginLeft: '80px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontWeight: 'bold', paddingRight: '58px', float: 'left' }}>
                          {validateData(data)
                            && validateData(data.weeklyRate) ?
                            formatter.format(data.weeklyRate) : ' - '
                          }
                          <hr style={{ border: '1px solid black', width: '169px' }}></hr>
                        </span>
                        <span style={{ fontWeight: 'bold', float: 'left', paddingRight: '77px' }}>after</span>
                        <span style={{ fontWeight: 'bold', float: 'left', paddingRight: '40px' }}>{validateData(data)
                          && validateData(data.weeklyTerm) ?
                          formatter.format(data.weeklyTerm) : ' - '
                        } <hr style={{ border: '1px solid black', width: '100px' }}></hr></span>
                        <span style={{ fontWeight: 'bold', float: 'left' }}>weeks</span>
                      </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: 'xx-large', fontWeight: 'bold', float: 'left' }}>Purchase Option Amount</div>

                    <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', marginTop: '10px', float: 'left' }}>
                      <div style={{ paddingLeft: '54px' }}>
                        {data.inventoryDetails.map((details: any) => {
                          return (
                            <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              {validateData(details) && validateData(details.inventoryNumber) ? details.inventoryNumber : ' - '}
                            </span>
                          )
                        })}
                      </div>
                    </div>
                    {/* <div style={{ marginBottom: "600px" }}></div> */}


                  </div>

                </div>

              </div>
            )
          }
        }
        else if (decodestore?.state == "PA") {
          if (value == (data.packagePriceId).toString()) {
            console.log("vvv");

            return (
              <div style={{ margin: '0px', fontFamily: 'Arial' }}>
                <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                  <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>

                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%', marginTop: '22%', paddingRight: '20px' }}>
                      <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', display: 'inline-block' }}>
                      {validateData(rateRegular)?
                          rateRegular : ' - '}
                      </h1>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold', marginBottom: '30px' }}>
                      {data.inventoryDetails.map((details: any) => {
                        return (

                          <span style={{ fontSize: '20px' }}>{validateData(details) && validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br /></span>
                        )
                      })}

                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ border: '1px solid black', padding: '6%', margin: '0% 20%' }}>

                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br />
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price<span style={{ top: '0', right: '0', position: 'absolute' }}>*</span></span>

                          <div style={{ marginTop: '20px' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                              {validateData(data)
                                && validateData(data.regularWeeklyRate) ?
                                formatter.format(data.regularWeeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.monthlyRate) ?
                              formatter.format(data.monthlyRate).replace(/^(\D+)/, '$1 ') : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                          </div>
                        </div>

                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.totalCost) ?
                              formatter.format(data.totalCost).replace(/^(\D+)/, '$1 ') : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Retail Value</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '58px' }}>
                        <div style={{ width: '100%' }}>

                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyRate) ?
                                formatter.format(data.weeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>

                          </div>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '85px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.monthlyTerm) ?
                              data.monthlyTerm : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Payments</span>

                          </div>
                        </div>
                      </div>
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '85px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px',fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.weeklyTerm) ?
                              data.weeklyTerm : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of</span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Payments</span>

                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '35px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.totalCost) ?
                              formatter.format(data.totalCost) : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Total Cost</span><br />

                          </div>
                        </div>

                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.cashPrice) ?
                              formatter.format(data.cashPrice).replace(/^(\D+)/, '$1 ') : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Cost of Lease Services</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left' }}>
                      <div style={{ paddingLeft: '54px' }}>
                        {data.inventoryDetails.map((details: any) => {
                          return (
                            <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              {validateData(details) && validateData(details.inventoryNumber) ? details.inventoryNumber : ' - '}
                            </span>
                          )
                        })}
                      </div>
                    </div>
                    {/* <div style={{marginBottom:"600px"}}></div> */}


                  </div>

                </div>

              </div>
            )
          }
        }
        else if (decodestore?.state == "PR") {
          if (value == (data.packagePriceId).toString()) {
            console.log("vvv");
            return (
              <div style={{ margin: '0px', fontFamily: 'Arial' }}>
                <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                  <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>

                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%',marginTop:'22%' }}>
                      <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', marginLeft: '37px', display: 'inline-block' }}>
                      {validateData(rateRegular)?
                          rateRegular : ' - '}
                      </h1>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold' }}>
                      {data.inventoryDetails.map((details: any) => {
                        return (

                          <span style={{ fontSize: '20px' }}>{validateData(details) && validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br /></span>
                        )
                      })}

                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ border: '1px solid black', padding: '6%', margin: '0% 20%' }}>

                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br />
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price<span style={{ top: '0', right: '0', position: 'absolute' }}>*</span></span>

                          <div style={{ marginTop: '20px' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                              {validateData(data)
                                && validateData(data.regularWeeklyRate) ?
                                formatter.format(data.regularWeeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.monthlyRate) ?
                              formatter.format(data.monthlyRate).replace(/^(\D+)/, '$1 ') : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                          </div>
                        </div>

                      </div>
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '58px' }}>
                        <div style={{ width: '100%' }}>

                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyRate) ?
                                formatter.format(data.weeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>

                          </div>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                        </div>
                      </div>
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '85px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.weeklyTerm) ?
                              data.weeklyTerm : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of</span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Payments</span>

                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '35px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.totalCost) ?
                              formatter.format(data.totalCost) : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Total Cost</span><br />

                          </div>
                        </div>

                      </div>
                    </div>
                    <div style={{ width: '550px', float: 'left', borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000', marginLeft: '85px' }}>

                      <div style={{ float: 'left', width: '33.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyTerm) ?
                                data.weeklyTerm : ' - '
                              }
                            </span>
                            <div>

                              <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Semi-Monthly Price</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ float: 'left', width: '33.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyTerm) ?
                                data.weeklyTerm : ' - '
                              }
                            </span>
                            <div>

                              <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of Payments</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ float: 'left', width: '33.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.totalCost) ?
                                formatter.format(data.totalCost) : ' - '
                              }
                            </span>
                            <div>

                              <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Total Cost</span><br />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '550px', float: 'left', border: '1px solid #000', marginLeft: '85px' }}>

                      <div style={{ float: 'left', width: '33.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.monthlyRate) ?
                                formatter.format(data.monthlyRate): ' - '
                              }
                            </span>
                            <div>
                              <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Monthly Price</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ float: 'left', width: '33.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.monthlyTerm) ?
                                data.monthlyTerm : ' - '
                              }
                            </span>
                            <div>
                              <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of Payments</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ float: 'left', width: '33.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.totalCost) ?
                                formatter.format(data.totalCost) : ' - '
                              }
                            </span>
                            <div>
                              <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>TotalCost</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%', float: 'left', fontSize: '17px', marginLeft: '-45px', textAlign: 'end', paddingTop: '11px' }}>*SALES TAX EXTRA ON ALL PRICES</div>
                    <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left' }}>
                      <div style={{ paddingLeft: '54px' }}>
                        {data.inventoryDetails.map((details: any) => {
                          return (
                            <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              {validateData(details) && validateData(details.inventoryNumber) ? details.inventoryNumber : ' - '}
                            </span>
                          )
                        })}
                      </div>
                    </div>
                    {/* <div style={{marginBottom:"600px"}}></div> */}


                  </div>

                </div>

              </div>
            )
          }
        }
        else if (decodestore?.state == "VT") {
          if (value == (data.packagePriceId).toString()) {
            console.log("vvv");
            return (
              <div style={{ margin: '0px', fontFamily: 'Arial' }}>
                <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                  <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>

                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%',marginTop:'22%'}}>
                      <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', marginLeft: '37px', display: 'inline-block' }}>
                      {validateData(rateRegular)?
                          rateRegular : ' - '}
                      </h1>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold' }}>
                      {data.inventoryDetails.map((details: any) => {
                        return (

                          <span style={{ fontSize: '20px' }}>{validateData(details) && validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br /></span>
                        )
                      })}

                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ border: '1px solid black', padding: '6%', margin: '0% 20%' }}>

                          <span style={{ fontSize: 'larger', fontWeight: 'bold' }}>Regular</span><br />
                          <span style={{ fontSize: 'larger', fontWeight: 'bold' }}>Weekly Price<span style={{ top: '0', right: '0', position: 'absolute' }}>*</span></span>

                          <div style={{ marginTop: '20px' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                              {validateData(data)
                                && validateData(data.regularWeeklyRate) ?
                                formatter.format(data.regularWeeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '58px' }}>
                        <div style={{ width: '100%' }}>
                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyRate) ?
                                formatter.format(data.weeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>

                          </div>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                        </div>
                      </div>
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '41px', whiteSpace: 'nowrap', marginBottom: '85px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.weeklyTerm) ?
                              data.weeklyTerm : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Number of</span><br />
                            <span style={{ fontSize: 'larger', fontWeight: 'bold' }}>Payments</span>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%', float: 'left' }}>

                      <div style={{ float: 'left', width: '32.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.cashPrice) ?
                                formatter.format(data.cashPrice) : ' - '
                              }
                            </span>
                            <div>

                              <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Cash Price</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <span style={{float:'left', margin:'20px 0px'}}>+</span>
                      <div style={{ float: 'left', width: '32.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px',fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.cashPrice) ?
                                formatter.format(data.cashPrice) : ' - '
                              }
                            </span>
                            <div>

                              <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Rent-To-Own Change</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <span style={{float:'left',margin:'20px 0px'}}>*</span>
                      <div style={{ float: 'left', width: '32.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.totalCost) ?
                                formatter.format(data.totalCost) : ' - '
                              }
                            </span>
                            <div>

                              <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Total Cost of OwnerShip</span><br />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '96%', float: 'left', borderTop: '1px solid #000', borderLeft: '1px solid #000', borderRight: '1px solid #000',marginTop:'10px',marginLeft:'20px' }}>

                      <div style={{ float: 'left', width: '50%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%',margin:'5px 0px' }}>
                            <span style={{ fontSize: '25px',fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.monthlyTerm) ?
                                formatter.format(data.monthlyRate) : ' - '
                              }
                            </span>
                            <div>

                              <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Monthly Price</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ float: 'left', width: '50%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%',margin:'5px 0px' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyTerm) ?
                                data.weeklyTerm : ' - '
                              }
                            </span>
                            <div>

                              <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Number of Payments</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '96%', float: 'left', border: '1px solid #000',marginBottom:'10px',marginLeft:'20px'}}>

                      <div style={{ float: 'left', width: '32.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%',margin:'5px 0px' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.cashPrice) ?
                                formatter.format(data.cashPrice) : ' - '
                              }
                            </span>
                            <div>
                              <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Cash Price</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <span style={{float:'left', margin:'20px 0px'}}>+</span>
                      <div style={{ float: 'left', width: '32.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%',margin:'5px 0px' }}>
                            <span style={{ fontSize: '25px',fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyTerm) ?
                                data.weeklyTerm : ' - '
                              }
                            </span>
                            <div>
                              <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Rent-To-Own Change</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                      <span style={{float:'left',margin:'20px 0px'}}>*</span>
                      <div style={{ float: 'left', width: '32.33%' }}>
                        <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                          <div style={{ width: '100%',margin:'5px 0px' }}>
                            <span style={{ fontSize: '25px',fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.totalCost) ?
                                formatter.format(data.totalCost) : ' - '
                              }
                            </span>
                            <div>
                              <span style={{ fontSize: 'larger', fontWeight: 'bold', borderTop: '1px solid black' }}>Total Cost of Ownership</span><br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{width:'100%',float: 'left',marginLeft:'15px',padding:'5px'}}>
                    <div style={{float: 'left', fontSize: '16px', textAlign: 'end', paddingTop: '11px',display:'flex' }}>
                      <div style={{border:'1px solid black',width:'20px',height:'20px'}}></div>
                      <div style={{margin:'4px 10px',fontWeight: 'bold'}}>All Items new</div>
                    </div>
                    <div style={{ width: '60%', float: 'left', fontSize: '16px', textAlign: 'end', paddingTop: '11px',display:'flex' }}>
                      <CloseIcon style={{border:'1px solid black',width:'20px',height:'20px'}}/>
                      <div style={{margin:'4px 10px',fontWeight: 'bold'}}>Some used items. See individual tags for details.</div>
                      </div>
                    </div>
                    <div style={{ width:'100%',float:'left',margin:'5px'}}>
                    <div style={{ textAlign: 'left' }}>
                    <span style={{ fontSize: '14px' }}>Rent-to-own agreement. You will not own the merchandise until the total cost of ownership is paid in full or you exercise your early purchase option</span>
                  </div>
                    </div>
                    <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left' }}>
                      <div style={{ paddingLeft: '54px' }}>
                        {data.inventoryDetails.map((details: any) => {
                          return (
                            <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              {validateData(details) && validateData(details.inventoryNumber) ? details.inventoryNumber : ' - '}
                            </span>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            )
          }
        }
        else if (decodestore?.state == "WV") {
          if (value == (data.packagePriceId).toString()) {
            console.log("vvv");
            return (
              <div style={{ margin: '0px', fontFamily: 'Arial' }}>
                <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                  <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>

                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%', marginTop: '22%', paddingRight: '20px' }}>
                      <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', display: 'inline-block' }}>
                      {validateData(rateRegular)?
                          rateRegular : ' - '}
                      </h1>
                    </div>

                    <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold', marginBottom: '30px' }}>
                      {data.inventoryDetails.map((details: any) => {
                        return (

                          <span style={{ fontSize: '20px' }}>{validateData(details) && validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br /></span>
                        )
                      })}

                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ border: '1px solid black', padding: '6%', margin: '0% 20%' }}>

                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br />
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price<span style={{ top: '0', right: '0', position: 'absolute' }}>*</span></span>

                          <div style={{ marginTop: '20px' }}>
                            <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                              {validateData(data)
                                && validateData(data.regularWeeklyRate) ?
                                formatter.format(data.regularWeeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.monthlyRate) ?
                              formatter.format(data.monthlyRate).replace(/^(\D+)/, '$1 ') : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                          </div>
                        </div>

                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                              && validateData(data.totalCost) ?
                              formatter.format(data.totalCost).replace(/^(\D+)/, '$1 ') : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Rental Value</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '58px' }}>
                        <div style={{ width: '100%' }}>

                          <div style={{ paddingTop: '15px' }}>
                            <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                              {validateData(data)
                                && validateData(data.weeklyRate) ?
                                formatter.format(data.weeklyRate).replace(/^(\D+)/, '$1 ') : ' - '
                              }
                            </span>

                          </div>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '85px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.weeklyTerm) ?
                              data.weeklyTerm : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Payments</span>

                          </div>
                        </div>
                      </div>
                    </div>


                    <div style={{ float: 'left', width: '33.33%' }}>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '85px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px',fontWeight:'bold', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.monthlyTerm) ?
                              data.monthlyTerm : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of</span><br />
                            <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Payments</span>

                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '35px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.totalCost) ?
                              formatter.format(data.totalCost) : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Total Cost of All Payments</span><br />

                          </div>
                        </div>

                      </div>
                      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                        <div style={{ width: '100%' }}>
                          <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                              && validateData(data.cashPrice) ?
                              formatter.format(data.cashPrice).replace(/^(\D+)/, '$1 ') : ' - '
                            }
                          </span>
                          <div>

                            <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Rent-To-Own Charge</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left' }}>
                      <div style={{ paddingLeft: '54px' }}>
                        {data.inventoryDetails.map((details: any) => {
                          return (
                            <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                              {validateData(details) && validateData(details.inventoryNumber) ? details.inventoryNumber : ' - '}
                            </span>
                          )
                        })}
                      </div>
                    </div>
                    {/* <div style={{marginBottom:"600px"}}></div> */}


                  </div>

                </div>

              </div>
            )
          }
        }
        else if(['CT','HI','MI','NJ','OH'].includes(decodestore?.state)){
        if(value==(data.packagePriceId).toString())
        {
          console.log("vvv");
          return(
            <div style={{ margin: '0px', fontFamily: 'Arial' }}>
              <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>
    
                  <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%', marginTop:'22%' }}>
                    <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', marginLeft: '37px', display: 'inline-block' }}>
                    {validateData(rateRegular)?
                          rateRegular : ' - '}
                    </h1>
                  </div>
    
                  <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold' }}>
                  {data.inventoryDetails.map((details:any)=>{
                      return(
                        
                        <span style={{ fontSize: '20px' }}>{validateData(details)&& validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br/></span>
                        )
                    })}

                    </div>
    
    
                  <div style={{ float: 'left', width: '33.33%' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{border: '1px solid black',padding:'6%',margin:'0% 20%'}}>
    
                        <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br/>
                        <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price</span>

                        <div>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                                && validateData(data.regularWeeklyRate)?
                                formatter.format(data.regularWeeklyRate) : ' - '
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                        {validateData(data)
                                && validateData(data.monthlyRate)?
                                formatter.format(data.monthlyRate) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                        </div>
                      </div>
    
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                        {validateData(data)
                                && validateData(data.totalCost)?
                                formatter.format(data.totalCost) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Total Cost</span>
                        </div>
                      </div>
                    </div>
                  </div>
    
    
                  <div style={{ float: 'left', width: '33.33%' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
    
                        <div style={{ paddingTop: '15px' }}>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.weeklyRate)?
                                formatter.format(data.weeklyRate) : ' - '
                            }
                            </span>
    
                        </div>
                        <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                      </div>
                    </div>
    
                  </div>
    
    
                  <div style={{ float: 'left', width: '33.33%' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.weeklyTerm)?
                                data.weeklyTerm : ' - '
                            }
                        </span>
                        <div style={{ paddingTop: '15px' }}>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br/>
                          <span style={{ fontSize: 'larger', fontWeight: '100px'}}>Payments</span>

                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.monthlyTerm)?
                                data.monthlyTerm : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br/>
                          <span style={{ fontSize: 'larger', fontWeight: '100px'}}>Payments</span>

                        </div>
                      </div>
    
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.cashPrice)?
                                formatter.format(data.cashPrice) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Cash Price</span>
                        </div>
                      </div>
                    </div>
                  </div>
    
    
                  <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left' }}>
                  <div style={{ paddingLeft: '54px' }}>
                    {data.inventoryDetails.map((details:any)=>{
                      return(
                        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          {validateData(details)&&validateData(details.inventoryNumber)?details.inventoryNumber:' - '}
                        </span>
                      )
                    })}
                    </div>
                  </div>
                  <div style={{marginBottom:"600px"}}></div>
    
    
                </div>
    
              </div>
    
            </div>
          )
        }
      }
        else{
        if(value==(data.packagePriceId).toString())
        {
          console.log("vvv");
          return(
            <div style={{ margin: '0px', fontFamily: 'Arial' }}>
              <div style={{ margin: '0px', width: '100%', float: 'left' }}>
                <div style={{ width: '100%', fontFamily: 'Arial', margin: '0px auto 0px auto' }}>
    
                  <div style={{ width: '100%', textAlign: 'center', marginBottom: '8%', marginTop:'16%' }}>
                    <h1 style={{ fontWeight: 'bold', fontSize: '80px', textAlign: 'center', marginLeft: '37px', display: 'inline-block' }}>
                    {validateData(rateRegular)?
                          rateRegular : ' - '}
                    </h1>
                  </div>
    
                  <div style={{ width: '100%', textAlign: 'center', display: 'inline-block', fontWeight: 'bold' }}>
                  {data.inventoryDetails.map((details:any)=>{
                      return(
                        
                        <span style={{ fontSize: '20px' }}>{validateData(details)&& validateData(details.inventoryDesc) ? details.inventoryDesc : ' - '}<br/></span>
                        )
                    })}

                    </div>
    
    
                  <div style={{ float: 'left', width: '33.33%', paddingTop: '4px' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{border: '1px solid black',padding:'6%',margin:'0% 20%'}}>
    
                        <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Regular</span><br/>
                        <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Weekly Price</span>

                        <div>
                          <span style={{ fontSize: '25px', fontWeight: 'bold', marginTop: '20px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                            {validateData(data)
                                && validateData(data.regularWeeklyRate)?
                                formatter.format(data.regularWeeklyRate) : ' - '
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                        {validateData(data)
                                && validateData(data.monthlyRate)?
                                formatter.format(data.monthlyRate) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Monthly Price</span>
                        </div>
                      </div>
    
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px', borderBottom: '1px solid black' }}>
                        {validateData(data)
                                && validateData(data.totalCost)?
                                formatter.format(data.totalCost) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px' }}>Total Cost</span>
                        </div>
                      </div>
                    </div>
                  </div>
    
    
                  <div style={{ float: 'left', width: '33.33%', paddingTop: '4px' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
    
                        <div style={{ paddingTop: '15px' }}>
                          <span style={{ fontSize: 'xx-large', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.weeklyRate)?
                                formatter.format(data.weeklyRate) : ' - '
                            }
                            </span>
    
                        </div>
                        <span style={{ fontSize: 'xx-large', fontWeight: 'bold', borderTop: '1px solid black' }}>Weekly Price*</span>
                      </div>
                    </div>
    
                  </div>
    
    
                  <div style={{ float: 'left', width: '33.33%', paddingTop: '4px' }}>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', fontWeight: 'bold', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.weeklyTerm)?
                                data.weeklyTerm : ' - '
                            }
                        </span>
                        <div style={{ paddingTop: '15px' }}>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br/>
                          <span style={{ fontSize: 'larger', fontWeight: '100px'}}>Payments</span>

                        </div>
                      </div>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.monthlyTerm)?
                                data.monthlyTerm : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Number of </span><br/>
                          <span style={{ fontSize: 'larger', fontWeight: '100px'}}>Payments</span>

                        </div>
                      </div>
    
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', whiteSpace: 'nowrap', marginBottom: '30px' }}>
                      <div style={{ width: '100%', paddingLeft: '4px' }}>
                        <span style={{ fontSize: '25px', paddingRight: '4px' }}>
                            {validateData(data)
                                && validateData(data.cashPrice)?
                                formatter.format(data.cashPrice) : ' - '
                            }
                        </span>
                        <div>
    
                          <span style={{ fontSize: 'larger', fontWeight: '100px', borderTop: '1px solid black' }}>Cash Price</span>
                        </div>
                      </div>
                    </div>
                  </div>
    
    
                  <div style={{ width: '100%', fontSize: 'large', marginBottom: '20px', float: 'left' }}>
                  <div style={{ paddingLeft: '54px' }}>
                    {data.inventoryDetails.map((details:any)=>{
                      return(
                        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          {validateData(details)&&validateData(details.inventoryNumber)?details.inventoryNumber:' - '}
                        </span>
                      )
                    })}
                    </div>
                  </div>
                  <div style={{marginBottom:"600px"}}></div>
    
    
                </div>
    
              </div>
    
            </div>
          )
        }
      }
      })
    });
  };

  return <React.Fragment>{printPackagePrice()}</React.Fragment>;
}
