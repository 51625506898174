/* eslint-disable */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { ReactComponent as Uploading } from '../../assets/images/cloud-uploading.svg';
import BlueCard from './blueCard';
import WhiteCard from './whiteCard';
import { Usercontext } from './searchResults';
import { GetEmployeeID, GetRole } from '../../../api/user';
import CurrencyInput from 'react-currency-input-field';
// import { AgreementContext } from '../../../context/AgreementContext';
import { addedItemStyles } from '../../componentstyles/addedItemStyles';
// import {agreementStyle} from './Test';
import TwoFactorComponent from '../../Shared/secondAuthen';
// import Erroricon from '../../../assets/images/erroricon.png';
import { PackagePriceContext } from '../../../context/PackagePriceTagContext';
import { ReactComponent as Erroricon } from '../../../assets/images/error-icon.svg';
import { InventoryMajorContext } from '../../../context/inventoryMajorContext';

import {
  RACTextbox,
  RACSelect,
  RACModalCard,
  RACButton,
  Grid,
  Card,
  CardContent,
  Typography,
  RACChip,
} from '@rentacenter/racstrap';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
const AddedItem = (props: any) => {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classes = props.throwStyle;
  const addedItemClassName = addedItemStyles();
  const shareData: any = useContext(Usercontext);
  const { initialAgrData, initialAgrTerm } = useContext(PackagePriceContext);
  const { priceOverrideLimitDetails, setPriceOverrideLimitDetails, termOverrideLimitDetails } = useContext(InventoryMajorContext)
  const [SecondFactorEnabled, setSecondFactorEnabled] = useState(false);
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };
  const needSLAObj = {
    needSLAController: false,
  };
  const [percentCalculation, setpercentCalculation] = useState(0);
  const [SLAController, setSLAController] = useState(SLAObj);
  const [needSLA, setneedSLA] = React.useState(needSLAObj);
  const [thresHoldExceptionMessage, setthresHoldExceptionMessage] = useState<any>('');
  const [initialAgreementRate, setinitialAgreementRate] = useState<any>();
  const [initialAgreementTerm, setIntialAgreementTerm] = useState<any>();
  const [invalidTermPopup, setInvalidTermPopup] = useState(false)

  const [agrRate, setagrRate]: any = useState('0.00');
  const [agrTerm, setagrTerm] = useState('0.00');
  const [openRateAlertModal, setopenRateAlertModal] = useState(false);
  const [openTermAlertModal, setopenTermAlertModal] = useState(false);

  const [agrTotal, setAgrTotal] = useState('0.00');
  const [cashPrice, setCashPrice] = useState('0.00');
  const [slaOverrideInvalid, setslaOverrideInvalid] = useState('');
  const [conditionLoader, setconditionLoader] = useState<boolean>(true);
  const [enableZeroValidate, setenableZeroValidate] = React.useState(false);
  const [invalidTerm, setinvalidTerm] = React.useState(false);
  const [slaErrorMessage, setslaErrorMessage] = useState('')
  const [controlCurrencyInput, setcontrolCurrencyInput] = React.useState(false);
  const [SLAMinRole, setSLAMinRole] = useState('');
  const ModuleName = 'Edit AgmtRt W/O Competitor';
  const [CurrentRole, setCurrentRole] = useState('');

  const [controlCompetitor, setControlCompetitor] = useState(true);
  const [roleFromSLA, setroleFromSLA] = useState('');
  //const [pricedata, setpricedata] = React.useState([]);
  const [proof, setproof] = useState('');
  const [checkedState, setcheckedState] = useState(false);
  const [throwPackageValue, setthrowPackageValue] = useState<any>();

  const configDataObj = priceOverrideLimitDetails
  const configTermDataObj = termOverrideLimitDetails
  const [configData, setConfigData] = useState<any>(configDataObj);
  const [termValidationConfigData, settermValidationConfigData] = useState<any>(configTermDataObj)

  useEffect(() => {
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
        }
      }
    };
    // GettingRole();
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setCurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
  }, []);

  useEffect(() => {
    console.log('before if condition', props.priceexceptionMessage);
    setthrowPackageValue(props.throwPackage);
    if (
      props.priceexceptionMessage !== null &&
      props.priceexceptionMessage !== undefined
    ) {
      console.log(
        'inside props data of price exception',
        props.priceexceptionMessage
      );
      handleExceptionRevert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priceexceptionMessage, props.throwPackage]);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (shareData !== undefined) {
      console.log('Added Item Shared Data', shareData);
      setagrRate(shareData.agreementPricing.agreementRate);
      setagrTerm(shareData.agreementPricing.agreementTerm);
      setAgrTotal(shareData.agreementPricing.agreementTotal);
      setCashPrice(shareData.agreementPricing.cashPrice);
      if (initialAgrData === undefined) {
        // setinitialAgrData(shareData.agreementPricing.agreementRate);
        setinitialAgreementRate(shareData.agreementPricing.agreementRate);
        setIntialAgreementTerm(shareData?.agreementPricing?.agreementTerm)

      } else {
        setinitialAgreementRate(initialAgrData);
        setIntialAgreementTerm(initialAgrTerm);

      }
      if (initialAgrData !== undefined) {
        setinitialAgreementRate(initialAgrData);
        setIntialAgreementTerm(initialAgrTerm);

      }
    } else {
      setagrRate('');
      setagrTerm('');
      setAgrTotal('');
      setCashPrice('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proof, shareData, initialAgrData, initialAgrTerm]); // this method is used to validate the proof //PS-8

  const agrRatechange = (AgrRate: any) => {
    setagrRate(AgrRate);
  };
  // this method will store the new agr Rate value

  const agrTermchange = (AgrTerm: any) => {
    setagrTerm(AgrTerm);
  }; // this method will store the new agr Term value

  const handleExceptionRevert = () => {
    if (shareData !== undefined) {
      setagrRate(shareData.agreementPricing.agreementRate);
      setagrTerm(shareData.agreementPricing.agreementTerm);
    }
  };
  const closeInvalidTermAndRate = () => {
    setDefault();
    setinvalidTerm(false);
    setenableZeroValidate(false);
  };

  const blurFunction = (functionController: any): any => {
    console.log('checking agrrate', agrRate);
    console.log('initialAgreementRate', initialAgreementRate);
    console.log(
      'checking shareData.agreementPricing.agreementRate',
      shareData.agreementPricing.agreementRate
    );

    if (
      functionController == 'RateChange' &&
      Number(agrRate) !== Number(shareData.agreementPricing.agreementRate)
    ) {
      const values = Object.values(configDataObj);
      const numericValues = values.filter((value): value is number => typeof value === 'number');
      const maxPercentageEdit = Math.max(...numericValues);
      const maxAllowablbeLimit = (Number(initialAgreementRate) * Number(maxPercentageEdit)) / 100
      let percentCalculationInBlur: any
      percentCalculationInBlur = Math.abs(
        ((initialAgreementRate - agrRate) * 100) / initialAgreementRate
      );
      percentCalculationInBlur = (Math.floor(Number(percentCalculationInBlur) * 100) / 100).toFixed(2)
      setpercentCalculation(Number(percentCalculationInBlur));
      debugger
      if (Number(agrRate) <= Number(initialAgreementRate)) {
        console.log('success reach of if in rate chage');

        let minRole = '';
        let enteredIf = false;
        let output;

        const Roles = [
          'FSC-CAR',
          'CAR',
          'RPMT',
          'FSC-AM',
          'AM',
          'FSC-LAM',
          'LAM',
          'FSC-SMT',
          'SMT',
          'FSC-SM',
          'SM',
          'FSC-SSM',
          'SSM',
          'CAM',
          'FSC-DMT',
          'DMT',
          'FSC-DM',
          'DM',
          'FSC-RDT',
          'RDT',
          'FSC-RD',
          'RD'
        ];

        if (Number(maxPercentageEdit) < Number(percentCalculationInBlur)) {

          if (CurrentRole == 'RD' || CurrentRole == 'FSC-RD' || CurrentRole == 'RDT' || CurrentRole == 'FSC-RDT') {
            setopenRateAlertModal(true)
            setslaErrorMessage(`Rate is invalid. Allowable Rate Values are $${Number(maxAllowablbeLimit).toFixed(2)} to $${Number(initialAgreementRate).toFixed(2)}`)
          }
          else {
            setopenRateAlertModal(true)
            setslaErrorMessage(`Override is invalid. SLA user can override from $${Number(maxAllowablbeLimit).toFixed(2)} to $${Number(initialAgreementRate).toFixed(2)}`)
          }
        }
        else {
          for (const item in configDataObj) {
            debugger
            if (
              configData[item] == Number(percentCalculationInBlur).toFixed(2) &&
              Number(percentCalculationInBlur) !== 0
            ) {
              debugger
              minRole = item;
              output = item;
              setSLAMinRole(item);
              if (Roles.indexOf(String(CurrentRole)) < Roles.indexOf(item)) {
                setneedSLA({ ...needSLA, needSLAController: true });
                enteredIf = true;
              }
              break;
            } else if (configData[item] > Number(percentCalculationInBlur)) {
              debugger
              minRole = item;
              output = item
              setSLAMinRole(item);
              if (Roles.indexOf(String(CurrentRole)) < Roles.indexOf(item)) {
                setneedSLA({ ...needSLA, needSLAController: true });
                enteredIf = true;
              }
              break;
            }
          }

          console.log('logged roles', CurrentRole);

          debugger
          const groupedData: { [key: string]: string[] } = {};

          for (const [key, value] of Object.entries(configData)) {
            const stringValue = String(value); // Convert value to string
            if (!groupedData[stringValue]) {
              groupedData[stringValue] = [key];
            } else {
              groupedData[stringValue].push(key);
            }
          }

          console.log("groupedData", groupedData)
          let currentRoleValue: any;
          debugger
          for (const key of Object.keys(groupedData)) {
            if (groupedData[key].includes(CurrentRole)) {
              // Convert key to number if needed
              currentRoleValue = parseInt(key);
              // Once we find the  role, we can break the loop
              break;
            }
          }
          debugger
          console.log("currentRoleValue", currentRoleValue)

          let allowedRate;
          let minAmount;
          allowedRate = initialAgreementRate * (1 * currentRoleValue / 100);
          minAmount = initialAgreementRate - allowedRate;
          setthresHoldExceptionMessage(
            `Coworker can only change rate upto $${Number(
              minAmount.toString()
            ).toFixed(2)} second level approval is required for this change.`
          );

          if (enteredIf === false) {
            console.log('entered if pricing call', enteredIf);

            Number(agrRate) < 1
              ? setenableZeroValidate(true)
              : props.setreceiveAgreementRate(agrRate);
          }
        }
      } else {

        Number(agrRate) < 1
          ? setenableZeroValidate(true)
          : props.setreceiveAgreementRate(agrRate);
      }

    } else if (
      functionController == 'TermChange' &&
      agrTerm !== shareData.agreementPricing.agreementTerm
    ) {
      const values = Object.values(configTermDataObj);
      const numericValues = values.filter((value): value is number => typeof value === 'number');
      const maxPercentageEdit = Math.max(...numericValues);
      const maxAllowablbeLimit = (Number(initialAgreementTerm) * Number(maxPercentageEdit)) / 100
      const percentCalculationInBlur = Math.abs(
        Math.ceil(((Number(initialAgreementTerm) - Number(agrTerm)) * 100) / Number(initialAgreementTerm))
      );
      console.log("percentCalculationInBlurCheck", percentCalculationInBlur)
      debugger

      let minRole = '';
      let enteredIf = false;
      let output;

      const Roles = [
        'FSC-CAR',
        'CAR',
        'RPMT',
        'FSC-AM',
        'AM',
        'FSC-LAM',
        'LAM',
        'FSC-SMT',
        'SMT',
        'FSC-SM',
        'SM',
        'FSC-SSM',
        'SSM',
        'CAM',
        'FSC-DMT',
        'DMT',
        'FSC-DM',
        'DM',
        'FSC-RDT',
        'RDT',
        'FSC-RD',
        'RD'
      ];
      if (Number(agrTerm) < Number(10) || Number(agrTerm) > Number(initialAgreementTerm)) {
        setInvalidTermPopup(true)
      }
      else if (Number(maxPercentageEdit) < Number(percentCalculationInBlur)) {
        if (CurrentRole == 'RD' || CurrentRole == 'FSC-RD' || CurrentRole == 'RDT' || CurrentRole == 'FSC-RDT') {
          setopenTermAlertModal(true)
          setslaErrorMessage(`Term is invalid. Allowable Term Values are ${Math.ceil(Number(maxAllowablbeLimit))} to ${Number(initialAgreementTerm)}`)
          return

        }
        else {
          setopenTermAlertModal(true)
          setslaErrorMessage(`Override is invalid. SLA user can override from ${Math.ceil(Number(maxAllowablbeLimit))} to ${Number(initialAgreementTerm)}`)
          return
        }
      }
      else {
        for (const item in configTermDataObj) {
          debugger

          if (
            termValidationConfigData[item] == Number(percentCalculationInBlur).toFixed(2) &&
            Number(percentCalculationInBlur) !== 0
          ) {
            debugger
            minRole = item;
            output = item;
            setSLAMinRole(item);
            if (Roles.indexOf(String(CurrentRole)) < Roles.indexOf(item)) {
              setneedSLA({ ...needSLA, needSLAController: true });
              enteredIf = true;
            }
            break;
          } else if (termValidationConfigData[item] > Number(percentCalculationInBlur)) {
            debugger
            minRole = item;
            output = item
            setSLAMinRole(item);

            if (Roles.indexOf(String(CurrentRole)) < Roles.indexOf(item)) {
              setneedSLA({ ...needSLA, needSLAController: true });
              enteredIf = true;
            }
            break;
          }
        }

        console.log('logged roles', CurrentRole);

        debugger
        const groupedData: { [key: string]: string[] } = {};

        for (const [key, value] of Object.entries(termValidationConfigData)) {
          const stringValue = String(value); // Convert value to string
          if (!groupedData[stringValue]) {
            groupedData[stringValue] = [key];
          } else {
            groupedData[stringValue].push(key);
          }
        }

        console.log("groupedData", groupedData)
        let currentRoleValue: any;
        debugger
        for (const key of Object.keys(groupedData)) {
          if (groupedData[key].includes(CurrentRole)) {
            // Convert key to number if needed
            currentRoleValue = parseInt(key);
            // Once we find the  role, we can break the loop
            break;
          }
        }
        debugger
        console.log("currentRoleValue", currentRoleValue)

        let allowedRate;
        let minAmount: any;
        allowedRate = initialAgreementTerm * (1 * currentRoleValue / 100);
        minAmount = initialAgreementTerm - allowedRate;
        setthresHoldExceptionMessage(
          `Coworker can only change term upto ${Number(
            Math.ceil(minAmount.toString())
          )} second level approval is required for this change.`
        );
        if (enteredIf === false) {
          console.log('entered if pricing call', enteredIf);
          props.setreceiveAgreementTerm(agrTerm);
        }
      }
    }
  };

  const RateTermChange = (stateController: any, e?: any) => {
    if (
      e.key == 'Enter' &&
      stateController == 'RateChange' &&
      agrRate !== shareData.agreementPricing.agreementRate
    ) {
      // {
      (document.getElementById('AgrRate') as HTMLInputElement).blur();
      // parseInt(agrRate) < 1
      //   ? setinvalidTerm(true)
      //   : props.setreceiveAgreementRate(agrRate);
      // }
    } else if (
      e.key == 'Enter' &&
      stateController == 'TermChange' &&
      agrTerm !== shareData.agreementPricing.agreementTerm
    ) {
      // {
      (document.getElementById('AgrTerm') as HTMLInputElement).blur();
      //   parseInt(agrTerm) < 1
      //     ? setinvalidTerm(true)
      //     : props.setreceiveAgreementTerm(agrTerm);
      // }
      if (checkedState) {
        setcontrolCurrencyInput(true);
      }
    }
  };

  const temp = (itemID: any) => {
    props.triggeroneDelete(itemID);
  };

  const setDefault = () => {
    //alert('Hello');
    if (shareData !== undefined) {
      setagrRate(shareData.agreementPricing.agreementRate);
      setagrTerm(shareData.agreementPricing.agreementTerm);
    }

    setcheckedState(false);
    setcontrolCurrencyInput(false);
    props.triggerSetToDefault();
    setControlCompetitor(true);
  }; // this method will store the default  agr Term and Rate value //PS-7

  // const closeZeroValidate = () => {
  //   setDefault();
  //   setenableZeroValidate(false);
  // };

  const invalidTermDiv = (displayData: any) => {
    return (
      <div
        id="decisionengine"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="decisionengine"
        aria-hidden="true"
      >
        {/* <div className="modal-dialog">
  
              <div className="modal-content"> */}

        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
            {displayData}
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => closeInvalidTermAndRate()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const SLACancel = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
    setagrRate(shareData.agreementPricing.agreementRate);
    setagrTerm(shareData?.agreementPricing?.agreementTerm);

  };
  const SLACompleted = (value: any) => {
    setSLAController({ ...SLAController, TwoFactorCompleted: value });
    setSecondFactorEnabled(false);
  };

  const acceptSLA = () => {
    setneedSLA({ ...needSLA, needSLAController: false });
    setSecondFactorEnabled(true);
  };

  const SLAModal = (displayData: any) => {
    return (
      <div id="decisionengine">
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
            {displayData}
          </Typography>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
            Do you want to proceed ?
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.me3}
            size="large"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setneedSLA({ ...needSLA, needSLAController: false });
              setagrRate(shareData.agreementPricing.agreementRate);
              setagrTerm(shareData?.agreementPricing?.agreementTerm)
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => acceptSLA()}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  // useEffect(() => {
  //   if (SLAController.TwoFactorCompleted) {
  //     parseInt(agrRate) < 1
  //       ? setinvalidTerm(true)
  //       : props.setreceiveAgreementRate(agrRate);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [SLAController.TwoFactorCompleted]);

  useEffect(() => {
    console.log(
      'SLAController.TwoFactorCompleted',
      SLAController.TwoFactorCompleted
    );
    console.log('percentCalculation in use efect', percentCalculation);

    if (SLAController.TwoFactorCompleted) {
      // parseInt(agrRate) < 1
      //   ? setinvalidTerm(true)
      //   :
      if (Number(agrRate) !== Number(shareData.agreementPricing.agreementRate)) {
        props.setreceiveAgreementRate(agrRate);

      }
      else if (Number(agrTerm) !== Number(shareData?.agreementPricing?.agreementTerm)) {
        props.setreceiveAgreementTerm(agrTerm);
      }
    }
    //  else if (SLAController.TwoFactorCompleted && roleFromSLA == '008') {
    //   // parseInt(agrRate) < 1
    //   //   ? setinvalidTerm(true)
    //   //   : props.setreceiveAgreementRate(agrRate);
    //   console.log('expected point reached');

    //   props.setreceiveAgreementRate(agrRate);
    // }
    // else if (SLAController.TwoFactorCompleted && percentCalculation > 50) {
    //   console.log('reached the alert log');
    //   const allowedRate = Math.round(agrRate * 0.5);
    //   const minAmount = agrRate - allowedRate;
    //   setslaOverrideInvalid(
    //     `Override is invalid. SLA user can override from $${minAmount} to $${agrRate}`
    //   );
    //   setopenRateAlertModal(true);
    //   //alert('Override is invalid. SLA user can override from');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SLAController.TwoFactorCompleted]);
  const acceptRateAlert = () => {
    setopenRateAlertModal(false);
    setagrRate(shareData.agreementPricing.agreementRate);
  };
  const openRateAlert = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          {slaErrorMessage.includes('Allowable') ? null : <Erroricon className={classes.racErrorIcon} />}
          <Typography className={classes.racErrorFunction} style={{ fontSize: '16px' }}>
            {slaErrorMessage}
          </Typography>
        </Grid>
        <Grid className={classes.textCenter} style={{ marginTop: '20px' }}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={acceptRateAlert}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const acceptTermAlertUser = () => {
    setopenTermAlertModal(false);
    setagrTerm(shareData.agreementPricing.agreementTerm);
  };

  const openRateAlertUser = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          {slaErrorMessage.includes('Allowable') ? null : <Erroricon className={classes.racErrorIcon} />
          }
          <Typography className={classes.racErrorFunction} style={{ fontSize: '16px' }}>
            {slaErrorMessage}
          </Typography>
        </Grid>
        <Grid className={classes.textCenter} style={{ marginTop: '20px' }}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={acceptTermAlertUser}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };


  const acceptTermAlert = () => {
    setInvalidTermPopup(false);
    setagrTerm(shareData?.agreementPricing?.agreementTerm);
  }
  const openTermAlert = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>

          <Typography className={classes.racErrorFunction} style={{ fontSize: '16px' }}>
            Term is invalid. Allowable terms are 10 - {initialAgreementTerm}
          </Typography>
        </Grid>
        <Grid className={classes.textCenter} style={{ marginTop: '20px' }}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={acceptTermAlert}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const provideSLAData = (value: any) => {
    console.log('received provideSLA', value);
    const roleCode = value.substr(value.length - 3);
    console.log('received provideSLA after trim', roleCode);

    setroleFromSLA(roleCode);
  };

  return (
    <Grid item md={3}>
      {SecondFactorEnabled === true ? (
        <TwoFactorComponent
          // setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCancelClick={SLACancel}
          // setTwoFactorCompleted={setTwoFactorCompleted}
          setTwoFactorCompleted={SLACompleted}
          setRoleFromSLAModule={provideSLAData}
          moduleName={ModuleName}
          CurrentRole={CurrentRole}
          minRole={SLAMinRole}
          throwCurrentPage="addedItem"
          agrRate={Number(agrRate) != Number(initialAgreementRate) ? initialAgreementRate : null}
          agrTerm={Number(agrTerm) != Number(initialAgreementTerm) ? initialAgreementTerm : null}
        />
      ) : null}
      <Typography className={`${classes.title} ${classes.mt2}`} variant="h6">
        Package Items
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.p1}>
          <Card
            className={`${classes.racBlue} ${classes.card} ${classes.p3} ${classes.pb0}`}
          >
            <BlueCard
              throwPackage={throwPackageValue}
              triggerTemp={temp}
              throwStyle={props.throwStyle}
              selectedPackage={props.selectedPackage}
            />
          </Card>

          <div className={`${classes.mt3}`}>
            <div className={classes.row}>
              <Typography className={classes.title} variant="h6">
                Package Price Tag Total
              </Typography>
              <WhiteCard throwStyle={props.throwStyle} />
              <Grid md={12} className={`${classes.mb3} ${classes.flexCenter}`}>
                <Typography variant="caption" className={classes.formLabel}>
                  Pkg Rate
                </Typography>

                <Grid
                  className={`${addedItemClassName.inputGroup} ${classes.w50} ${classes.floatRight}`}
                >
                  <Typography className={addedItemClassName.inputGroupText}>
                    $
                  </Typography>

                  <CurrencyInput
                    id="AgrRate"
                    name="AgrRate"
                    value={agrRate}
                    className={`${classes.formControl} ${addedItemClassName.currencyAlign} ${classes.fontSemiBold}`}
                    decimalScale={2}
                    decimalsLimit={2}
                    onValueChange={(e) => agrRatechange(e)}
                    onBlur={() => blurFunction('RateChange')}
                    onKeyDown={(e) => RateTermChange('RateChange', e)}
                    disabled={controlCurrencyInput}
                  />
                </Grid>
              </Grid>
              <Grid md={12} className={`${classes.mb3} ${classes.flexCenter}`}>
                <Typography variant="caption" className={classes.formLabel}>
                  Pkg Term
                </Typography>

                <Grid
                  className={`${addedItemClassName.inputGroup} ${classes.w50} ${classes.floatRight}`}
                >
                  <RACTextbox
                    id="AgrTerm"
                    type="number"
                    name="itemNo"
                    isCurrency={false}
                    digitFormat="normal"
                    value={agrTerm}
                    OnChange={(e) => agrTermchange(e.target.value)}
                    Onblur={() => blurFunction('TermChange')}
                    //OnKeydown={(e) => checkAgrTermChange(e)}
                    OnKeydown={(e) => RateTermChange('TermChange', e)}
                  //onKeydown={(e) => checkAgrTermChange(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid md={12}>
                <Typography
                  variant="subtitle2"
                  className={`${classes.racpadLink} ${classes.textRight} ${classes.underlinetxtDecoration}`}
                  data-testid="SetToDefault"
                  style={{ cursor: 'pointer' }}
                  onClick={setDefault}
                >
                  Set to Default
                </Typography>
              </Grid>
            </div>
          </div>
          <Card
            className={`${classes.card} ${classes.racSkyBlue} ${classes.p3}`}
          >
            <Grid
              md={12}
              className={`${addedItemClassName.agrAddedTotal} ${classes.mb2}`}
            >
              <Typography
                variant="caption"
                className={`${classes.textBlue1} ${classes.fs7} ${classes.fontSemiBold}`}
              >
                Cash Price
              </Typography>

              <Typography
                variant="caption"
                className={`${classes.textBlue1} ${classes.font18} ${classes.fontBold}`}
                style={{ fontSize: '18px' }}
              >
                ${cashPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
            </Grid>
            <Grid
              md={12}
              className={`${addedItemClassName.agrAddedTotal} ${classes.mb2}`}
            >
              <Typography
                variant="caption"
                className={`${classes.textBlue1} ${classes.fs7} ${classes.fontSemiBold}`}
              >
                Package Total
              </Typography>

              <Typography
                variant="caption"
                className={`${classes.textBlue1} ${classes.font18} ${classes.fontBold}`}
                style={{ fontSize: '18px' }}
              >
                ${agrTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
            </Grid>
          </Card>
        </CardContent>
      </Card>

      <RACModalCard
        isOpen={invalidTerm}
        maxWidth="xs"
        borderRadius={'26px !important'}

      // children={invalidTermDiv()}
      >
        {invalidTermDiv('Invalid term')}
      </RACModalCard>

      <RACModalCard
        isOpen={enableZeroValidate}
        maxWidth="xs"
        borderRadius={'25px !important'}

      //children={enableZeroValidatediv()}
      >
        {invalidTermDiv('Invalid rate. Rate should be greater than $0.00')}
      </RACModalCard>

      <RACModalCard
        isOpen={needSLA.needSLAController}
        maxWidth="xs"
        borderRadius={'25px !important'}
      //children={enableZeroValidatediv()}
      >
        {SLAModal(thresHoldExceptionMessage)}
      </RACModalCard>
      <RACModalCard
        isOpen={openRateAlertModal}
        maxWidth={'sm'}
        borderRadius="25px !important"
        //eslint-disable-next-line react/no-children-prop
        children={openRateAlert()}
      ></RACModalCard>
      {/* Invalid Term Popup */}
      <RACModalCard
        isOpen={invalidTermPopup}
        maxWidth={'sm'}
        borderRadius="25px !important"
        //eslint-disable-next-line react/no-children-prop
        children={openTermAlert()}
      ></RACModalCard>
      {/* Invalid Term user popup */}
      <RACModalCard
        isOpen={openTermAlertModal}
        maxWidth={'sm'}
        borderRadius="25px !important"
        //eslint-disable-next-line react/no-children-prop
        children={openRateAlertUser()}
      ></RACModalCard>
    </Grid>
  );
};

export default AddedItem;
