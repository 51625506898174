/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { globalStyles } from '../../componentstyles/globalstyles';
import { searchResultStyles } from '../../componentstyles/searchResultStyles';
import { PackagePriceContext } from '../../../context/PackagePriceTagContext';
import ExceptionController from './exceptionController';
import { useLocation } from 'react-router-dom';
import ModalContainer from './modalContainer';
import { useHistory } from 'react-router';
import SearchFilter from './searchCriteria';
import DynamicGridComponent from './grid';
import { useParams } from 'react-router';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import AddedItem from './addedItem';
import PrintPackagePrice from '../PackagePriceTags/PackagePricePrintDoc'
import { getInventorySearch, getPricingClientCall, getPackagePriceTag, postbreakPackage, createPackage, getCurrentUser, getCoworkerDetails, featureFlagInfo } from '../../../api/user';
import TwoFactorComponent from '../../Shared/secondAuthen';
import { ADD_ON_PRICING, makePriceOverRiderolesAllocationFn, makeTermOverRiderolesAllocationFn } from '../../../constants/constants';
import { InventoryMajorContext } from '../../../context/inventoryMajorContext';

import {
  Grid,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  RACButton,
  RACModalCard,
} from '@rentacenter/racstrap';
import { transformConfigDetails } from '../../Shared/removeColumnHeader';

export const Usercontext = createContext({});

export interface columns {
  columnId: string;
  IsSorted: boolean;
  sortOrder: string;
  columnType?: 'link';
  columnTitle: string;
  IsSortable: boolean;
  columnColor?: string;
  subColumns?: columns[];
  handleColumnClick?: () => void;
}

interface Params {
  customerId: string;
}

const ItemSearchResult = (props: any) => {
  const { packagePriceTagsOne, setinitialAgrData, setInitialAgrTerm, setsearchCriteria, searchCriteria, packageContextValue: [packageDetailContext] } =
    useContext(PackagePriceContext);
  const { priceOverrideLimitDetails, setPriceOverrideLimitDetails, termOverrideLimitDetails, setTermOverrideLimitDetails, featureFlagDetails, setFeatureFlagDetails } = useContext(InventoryMajorContext)
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const search = useLocation().search;
  const history = useHistory()
  let coWorkerRole = '';
  const classes = globalStyles();
  const agrComponent = searchResultStyles();
  const [errorMsg, setErrorMsg] = useState<boolean>(false)
  const { customerId } = useParams<Params>();
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  const sortDirection = {
    ASC: 'asc',
    DESC: 'desc',
  };

  const RateTermCompetitor = {
    AgrRateInp: null,
    AgrTermInp: null,
    CompetitorCheck: false,
  };

  const resultControllerObj = {
    controlRecord: false,
    controlEmptyRecord: false,
    internalServerController: false,
  };

  const modalStates = {
    breakPopup: false,
    addAllPopUp: false,
    itemPricingFail: false,
    breakPackageCtrl: false,
    RateTermController: false,
    PackageExceptionPopup: false,
    packageExceptionController: false,
  };
  const ModuleName = 'Choosing Price Tag';
  const loaderObj = {
    Gridloader: true,
    masterLoader: false,
    loaderController: false,
  };
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };
  const [selectedPackagePriceTag, setselectedPackagePriceTag] = useState(null);
  const [SLAController, setSLAController] = useState(SLAObj);
  const deleteIdObj = { invDeleteId: '' };
  const [loaderBundle, setLoaderBundle] = useState(loaderObj);
  const [errorContent, seterrorContent] = useState<any>('')
  const [PricexeptionMessage, setPricexeptionMessage] = useState('');
  const [errorMsgCondition, seterrorMsgCondition] = useState<boolean>(false)
  const [currentCWRole, setcurrentCWRole] = useState('');
  const [printCreatePackage, setprintCreatePackage] = useState<any>()
  const [print, setPrint] = useState<boolean>(false)
  const [openPackageSelection, setopenPackageSelection] = useState(false);
  const [singleItemvalidate, setsingleItemvalidate] = useState<boolean>(false);
  const [deleteId, setdeleteId] = React.useState(deleteIdObj);
  const [modalController, setmodalController] = useState(modalStates);
  const [priceController, setpriceController] =
    useState<any>(RateTermCompetitor);
  const [resultController, setresultController] = useState(resultControllerObj);
  const [scrollLoader, setscrollLoader] = useState(false);
  const [secondLvlAuth, setsecondLvlAuth] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const [payloadSetNumber, setpayloadSetNumber] = useState<any>(1);
  const [moduleName, setmoduleName] = useState('');
  const [gridData, setGridData] = useState<any>();
  const [isPackage, setIsPackage] = useState(false);
  const [itemArray, setItemArray] = useState<any>([]);
  const [salesLeadData, setSalesLeadData] = useState();
  const [breakPriceIds, setbreakPriceIds]: any = useState([]);
  const [packagePriceTags, setPackagePriceTags] = useState<any>([]);
  const [bucketCopy, setBucketCopy] = useState<any>([]);
  const [collectIndex, setcollectIndex] = useState<any>();
  const [formException, setformException] = useState<any>();
  const [PackageItemId, setPackageItemId] = useState<any>();
  const [showAddedItem, setShowAddedItem] = useState(false);
  const [receivedpackage, setreceivedpackage] = useState<any>();
  const [packageCriteria, setpackageCriteria] = useState<any>();
  const [controlViewMode, setControlViewMode] = useState(false);
  const [packageDropdownValidate, setpackageDropdownValidate] = useState('')
  const [pricingException, setpricingException] = useState<any>();
  const [footerControlData, setfooterControlData] = useState(true);
  const [PackagePricingResponse, setPackagePricingResponse] = useState<any>();
  const [PackagePricingNameReq, setPackagePricingNameReq] = useState();
  const [internalContextData, setInternalContextProvider] = useState<any>();
  const [selectedPackage, setselectedPackage] = useState('');
  const [packageText, setPackageText] = useState<any>(false);
  const [packageList, setpackageListData] = useState();

  const [packageInvPopulator, setpackageInvPopulator] = useState([]);
  const [PackageleftValue, setPackageleftValue]: any = useState({});
  const [PackagerightValue, setPackagerightValue]: any = useState({});
  const [tagBlueLabel, settagBlueLabel]: any = useState({});
  const [leftValue, setleftValue]: any = useState({});
  const [openPriceTag, setOpenPriceTag] = useState(false);
  const [newSingleInvPricingInvArray, setnewSingleInvPricingInvArray]: any =
    useState([]);
  const [SecondFactorEnabled, setSecondFactorEnabled] = useState(false);
  const [packageBlueLabel, setpackageBlueLabel] = useState('');
  const [openPackagePriceTag, setopenPackagePriceTag] = useState(false);
  const [rightValue, setrightValue]: any = useState({});
  const [isPackageFlow, setisPackageFlow] = useState(false);
  const [editPackageAry, seteditPackageAry] = useState();
  const [hasBreakEdit, sethasBreakEdit] = useState([]);

  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme';
  const altersearch = showAddedItem
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid;



  useEffect(() => {
    const featureFlagCall = async () => {
      const StoreId = window.sessionStorage.getItem('storeNumber')
      const featureFlagReq = {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        paramKeyNames: [
          "FSC-CAR",
          "CAR",
          "RPMT",
          "FSC-AM",
          "AM",
          "FSC-LAM",
          "LAM",
          "FSC-SMT",
          "SMT",
          "FSC-SM",
          "SM",
          "FSC-SSM",
          "SSM",
          "CAM",
          "FSC-DMT",
          "DMT",
          "FSC-DM",
          "DM",
          "FSC-RDT",
          "RDT",
          "FSC-RD",
          "RD",
          ADD_ON_PRICING
        ]
      };

      const FeatureFlagResponse = await featureFlagInfo(featureFlagReq);

      if (FeatureFlagResponse.status == 200 && FeatureFlagResponse.data) {
        setFeatureFlagDetails(transformConfigDetails(FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails))
        const FeatureFlagData = await makePriceOverRiderolesAllocationFn(FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
        setPriceOverrideLimitDetails(FeatureFlagData);
        const TermValidationData = await makeTermOverRiderolesAllocationFn(FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
        setTermOverrideLimitDetails(TermValidationData)

      }

    };
    featureFlagCall();
  }, [])

  useEffect(() => {
    console.log(props);
    if (itemArray !== undefined && itemArray.length > 0) {
      console.log('selectedPackage in rate change', selectedPackage);
      setLoaderBundle({ ...loaderBundle, masterLoader: true });
      const AgrRateTerm = async () => {
        console.log('function call');
        let packageParameter: any = '';
        let packagePriceType: any = null;
        packageParameter = selectedPackage;
        if (isPackage) {
          packagePriceType = selectedPackagePriceTag;
        }
        const ClientCallResponse = await pricingCall(
          itemArray,
          null,
          isPackage,
          priceController.AgrRateInp != '' && priceController.AgrRateInp != null
            ? parseFloat(priceController.AgrRateInp)
            : null,
          priceController.AgrTermInp != '' && priceController.AgrTermInp != null
            ? parseInt(priceController.AgrTermInp)
            : null,
          priceController.CompetitorCheck,
          packageParameter,
          packagePriceType
        );
        console.log('ClientCallResponse', ClientCallResponse);
        setLoaderBundle({ ...loaderBundle, masterLoader: false });

        if (
          ClientCallResponse !== undefined &&
          ClientCallResponse.status == 200
        ) {
          setInternalContextProvider(ClientCallResponse.data);
        } else if (
          ClientCallResponse !== undefined &&
          ClientCallResponse.status == 400
        ) {
          setpricingException(ClientCallResponse.data.errors[0].error == "Invalid inventoryIds" ? 'No active price tags found for selected inventory' : ClientCallResponse.data.errors[0].error);
          setmodalController({ ...modalController, RateTermController: true });
        }
      };
      AgrRateTerm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceController.AgrRateInp, priceController.AgrTermInp]);


  useEffect(() => {
    console.log('packagePriceTagsmain', packagePriceTagsOne);
    if (packageDetailContext.packageName !== "") {
      setPackageText(true);
      setItemArray(packageDetailContext.primaryItem)
      setIsPackage(true)
      let packageParameter: any = '';
      packageParameter = selectedPackage;

      const packages = async () => {
        const response: any = await pricingCall(
          packageDetailContext.primaryItem,
          null,
          isPackage,
          null,
          packageDetailContext?.packageTerm ? parseInt(packageDetailContext.packageTerm) : null,
          false,
          packageDetailContext.packageName,
          null
        );


        console.log('packagesRes', response);
        if (response?.data && response?.status == 200) {
          setInternalContextProvider(response.data);
          setreceivedpackage(response.data.itemPricing[0].packageName)
          setpackageListData(response.data.packageInformation.packageTypeList);
          sethasBreakEdit(response.data.itemPricing);
          setselectedPackage(response.data.itemPricing[0].packageName);
          setShowAddedItem(true);
          const listOfPackage = response?.data?.packageInformation?.packageTypeList;
          const ruleForSelectedPackage = listOfPackage?.filter((obj: any) => obj?.packageName == packageDetailContext?.packageName);
          setpackageCriteria(
            ruleForSelectedPackage[0]?.packageCriteria
          );
          const uniqueChars = [...new Map(response.data.itemPricing.map((item: any) => [item.packagePriceId, item])).values()];

          // const packageIds = uniqueChars.filter((value: any) => {
          //   if (value.packagePriceId != null && value.packagePriceId != 'null') {
          //     console.log('value', value.packagePriceId)
          //     return parseInt(value.packagePriceId)
          //   }
          // })
          const packageIds: any[] = []
          uniqueChars.map((value: any) => {
            if (value.packagePriceId != null && value.packagePriceId != 'null') {
              console.log('valuepackagePriceId', value.packagePriceId)
              packageIds.push(Number(value.packagePriceId))
              seteditPackageAry(value.packagePriceId)
            }
          })
          //setbreakPriceIds(packageIds)
        }
        if (
          response.status === 200 &&
          response.data.packageInformation.packagePriceInfo.packagePrice.length > 1
        ) {
          const modalInvPopupator: any = [];
          response.data.itemPricing.map((obj: any) => {
            modalInvPopupator.push({
              inventoryNumber: obj.inventoryNumber,
              inventoryName: obj.inventoryName,
            });
          });
          console.log('dfdvfd dcfv542 modalInvPopupator', modalInvPopupator);
          setisPackageFlow(true);
          setpackageInvPopulator(modalInvPopupator);
          setopenPackagePriceTag(true);
          setpackageBlueLabel(
            response.data.packageInformation.packagePriceInfo.packageType
          );
          if (
            response.data.packageInformation.packagePriceInfo.packagePrice[0] ===
            'ACTIVE'
          ) {
            setPackageleftValue(
              response.data.packageInformation.packagePriceInfo.packagePrice[1]
            );
            setPackagerightValue(
              response.data.packageInformation.packagePriceInfo.packagePrice[0]
            );
          } else {
            setPackageleftValue(
              response.data.packageInformation.packagePriceInfo.packagePrice[0]
            );
            setPackagerightValue(
              response.data.packageInformation.packagePriceInfo.packagePrice[1]
            );
          }
        }
        else if (response.status == 400) {
          setmodalController({
            ...modalController,
            itemPricingFail: true,
          });
          setPricexeptionMessage(response.data.errors[0].error == "Invalid inventoryIds" ? 'No active price tags found for selected inventory' : response.data.errors[0].error);

        }
      };
      packages();
    }
  }, [packageDetailContext]);

  useEffect(() => {
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setcurrentCWRole(containerData?.GetRole());
      //setemployeeId(containerData?.GetEmployeeId());
      const roleObj = containerData?.GetRole()
      setcurrentCWRole(roleObj);
    }

    else {
      getCoWorkerRole()
    }
  }, [])

  const getCoWorkerRole = async () => {
    try {

      const currentUserRes: any = await getCurrentUser();
      console.log('getCurrentUser-apiResponse', currentUserRes);

      if (currentUserRes?.status === 200) {
        const EmployeeId = currentUserRes?.data?.employeeId;
        console.log('EmployeeId', EmployeeId);

        const payload = {
          coworkerId: EmployeeId,
          storesRequired: true
        }
        console.log('menuandstores-payload', payload);
        const coWorkerRoleObj: any = await getCoworkerDetails(payload);
        if (
          coWorkerRoleObj && coWorkerRoleObj?.status == 200 &&
          coWorkerRoleObj?.data?.coworkerProfile &&
          coWorkerRoleObj?.data?.coworkerProfile?.role
        ) {
          const roleObj = coWorkerRoleObj.data.coworkerProfile.role;
          coWorkerRole = roleObj;
          console.log('roleObj', roleObj);

          setcurrentCWRole(roleObj);

          console.log('coWorkerRoleObj', coWorkerRole);
        }
        /* eslint-disable no-console */
        console.log('coWorkerRoleObj', coWorkerRoleObj);
      }

    } catch (e: any) {
      console.log('Error in co worker role');
    }
  };

  const PackagedropdownOnClick = (value: any) => {

    console.log({ "PackagedropdownOnClick": value });
    setpackageDropdownValidate(value)
  };
  const OnPrintPrice = (module: any) => {
    setsecondLvlAuth(true);
    setmoduleName(module);
  }
  //AgrRateInp, AgrTermInp
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    console.log('vaue of id in useeffect', deleteId.invDeleteId);
    if (deleteId.invDeleteId !== '') {
      let deleteBlueCard = false
      if (editPackageAry !== undefined) {
        deleteBlueCard = hasBreakEdit.some((el: any) => el.inventoryId === deleteId.invDeleteId)
        console.log('deleteBlueCard', deleteBlueCard);
      }
      if (isPackage || deleteBlueCard) {
        setmodalController({ ...modalController, breakPackageCtrl: true });
        console.log('Came Inside Is Package');
      } else if (!isPackage) {
        console.log('Came Inside Not Package');
        deleteItem();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]); // this method is used to manipulate the data item based in the delete in the addedadd item menu


  const pricingCall = async (
    inventoriesValue: any,
    validateInventoryIdValue: any,
    packageRequiredValue: any,
    agreementRateValue: any,
    agreementTermValue: any,
    isCompetitorMatchValue: any,
    packageNameValue: any,
    packagePriceType: any
  ) => {
    const req: any = {
      storeNumber: sessionStorage.getItem('storeNumber'),
      inventoryInfo: inventoriesValue,
      validateInventoryId: parseInt(validateInventoryIdValue),
      packageRequired: packageRequiredValue,
      packageName: packageNameValue,
      packagePriceType: packagePriceType,
      agreementRate: agreementRateValue,
      agreementTerm: agreementTermValue,
      isCompetitorMatch: isCompetitorMatchValue,
    };
    console.log('pricingCall request', req);

    return await getPricingClientCall(req);
  };

  const closeModal = (controller: any): any => {
    console.log('received controller', controller);
    if (controller == 'addAllPopUp') {
      setmodalController({ ...modalController, addAllPopUp: false });
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    } else if (controller == 'breakPackageCtrl') {
      setmodalController({ ...modalController, breakPackageCtrl: false });
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    } else if (controller == 'RateTermController') {
      setmodalController({ ...modalController, RateTermController: false });
    } else if (controller == 'breakPopup') {
      setmodalController({ ...modalController, breakPopup: false });
    }
  };

  const deleteItem = () => {
    if (itemArray !== undefined && itemArray.length > 0) {
      console.log('Data Inside invDeleteId use effect', deleteId.invDeleteId);
      let UpdatedArray: any = [];
      itemArray.map((deleteObject: any, index: any) => {
        if (parseInt(deleteObject.inventoryId) === parseInt(deleteId.invDeleteId)) {
          UpdatedArray = itemArray.filter((item: any, filterIndex: any) => {
            console.log('filter copy item', item);
            return filterIndex != index;
          });
          console.log('Newly Updated Array', UpdatedArray);
          setItemArray(UpdatedArray);
        }
      });
      if (UpdatedArray.length <= 1) {
        console.log('reached null received package');
        setselectedPackage('');
        setreceivedpackage('');
      }

      let response: any;
      if (UpdatedArray.length > 0) {
        console.log('Inside if');
        const selectedPackageProvider =
          UpdatedArray.length > 1 ? selectedPackage : '';
        console.log('selectedPackageProvider logger', selectedPackageProvider);

        const triggerAPI = async () => {
          response = await pricingCall(
            UpdatedArray,
            null,
            false,
            null,
            null,
            false,
            selectedPackageProvider,
            null
          );
          setLoaderBundle({ ...loaderBundle, masterLoader: false });
          console.log('Inside Use Effect of invDeleteId', response);
          console.log('Came inside result if condition');
          if (response.data !== undefined && response.status == 200) {
            setinitialAgrData(response.data.agreementPricing.agreementRate);
            setInitialAgrTerm(response?.data?.agreementPricing?.agreementTerm)
            setShowAddedItem(true);
            setIsPackage(false);
            setfooterControlData(false);
            setInternalContextProvider(response.data);
            const uniqueChars = [...new Map(response.data.itemPricing.map((item: any) => [item.packagePriceId, item])).values()];
            const packageIds: any[] = []
            uniqueChars.map((value: any) => {
              if (value.packagePriceId != null && value.packagePriceId != 'null') {
                console.log('valuepackageDeleteaddItem', value.packagePriceId)
                packageIds.push(Number(value.packagePriceId))
              }
            })
            editPackageAry ? setbreakPriceIds(packageIds) : null
            if (response.data.packageInformation.packageTypeList !== null && Object.keys(response.data.packageInformation.packageTypeList)
              .length !== 0 &&
              response.data.packageInformation.packageTypeList !== '') {
              if (selectedPackageProvider) {
                const listOfPackage = response.data.packageInformation.packageTypeList;
                const ruleForSelectedPackage = listOfPackage.filter((obj: any) => obj.packageName == selectedPackageProvider);
                setpackageCriteria(
                  ruleForSelectedPackage[0].packageCriteria
                );

              }
              else {
                setpackageCriteria(
                  response.data.packageInformation.packageTypeList[0].packageCriteria
                );
              }

              setreceivedpackage(response.data.itemPricing[0].packageName);
            } else {
              setpackageCriteria(null);
            }
            setpackageListData(response.data.packageInformation.packageTypeList);
          }
          else if (response.status == 400) {
            setmodalController({
              ...modalController,
              itemPricingFail: true,
            });
            setPricexeptionMessage(response.data.errors[0].error == "Invalid inventoryIds" ? 'No active price tags found for selected inventory' : response.data.errors[0].error);

          }
        };
        triggerAPI();
      } else {
        setIsPackage(false);
        setLoaderBundle({ ...loaderBundle, masterLoader: false });
        console.log('Came inside deep else');
        setInternalContextProvider(undefined);
        setpackageCriteria(null);
        setShowAddedItem(false);
        setfooterControlData(true);
      }
    } else {
      console.log('Came inside else');
      setInternalContextProvider(undefined);
      setShowAddedItem(false);
      setfooterControlData(true);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    }
    const copyNum = deleteId.invDeleteId;
    const resultArr = bucketCopy.filter((data: any, index: any) => {
      return bucketCopy.indexOf(data) === index;
    });
    console.log('copy before map', bucketCopy);
    console.log('copyNum', copyNum);

    const refinedTableData: any = [];
    gridData.map((obj: any, index: any) => {
      if (obj.inventoryId !== copyNum) {
        refinedTableData.push(obj);
      }
    });

    resultArr.map((obj: any, index: any) => {
      console.log('copy map obj', obj);
      if (obj.inventoryId == copyNum) {
        const copyTableData = refinedTableData;
        copyTableData.push(obj);
        setGridData(copyTableData);

        const newCopy = bucketCopy.filter((item: any, filterIndex: any) => {
          console.log('filter copy item', item);
          return filterIndex != index;
        });
        console.log('copy after map', newCopy);
        setBucketCopy(newCopy);
      }
    });
  };

  const acceptBreakPackage = () => {
    deleteItem();
    setIsPackage(false);
    setmodalController({ ...modalController, breakPackageCtrl: false });
  };

  const declineBreakPackage = () => {
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    setmodalController({ ...modalController, breakPackageCtrl: false });
  };

  const searchClick = async (value: any, source: any) => {
    try {
      if (source == 'searchButtonClick') {
        setpayloadSetNumber(1);
        setLoaderBundle({
          ...loaderBundle,
          Gridloader: true,
          loaderController: true,
        });
        sethasMore(true);
      } else if (source == 'searchButtonNotClick') {
        setscrollLoader(true);
      }
      console.log('thsg6rd8z4vsc5search', value);

      const currentStore = sessionStorage.getItem('storeNumber');
      const req: any = {
        storeNumbers: [currentStore],
        itemNumber: value.itemNo == '' ? null : [value.itemNo],
        serialNumber: value.serialNo?.trim(),
        bracket: value.bracket?.trim(),
        brand: value.brand?.trim(),
        modelNumber: value.model?.trim(),
        statusId: value.statusId,
        subStatusId: salesLeadId == null ? [2] : [2, 6],
        conditionId:
          value.conditionId == '0' || value.conditionId == null
            ? null
            : parseInt(value.conditionId),
        department: value.departments,
        subDepartment: value.subDepartments,
        packageName: value.packageTypeval,
        isActivePriceTag: true,
        setNumber: source == 'searchButtonClick' ? 0 : payloadSetNumber,
      };
      console.log('defined payload', req);
      setsearchCriteria(value)
      const response: any = await getInventorySearch(req);
      if (response.status == 400 || response.status == 500) {
        setresultController({
          ...resultControllerObj,
          internalServerController: true,
        });
        setLoaderBundle({ ...loaderBundle, loaderController: false });
      } else if (response.status == 200 && response.data.response.length > 0) {
        if (source == 'searchButtonNotClick') {
          setpayloadSetNumber(payloadSetNumber + 1);
        }
        let furtherFilteredData: any = [];
        const priceFilteredData: any = [];
        if (itemArray.length > 0) {
          console.log('reached if of search');
          let pushMe = false;
          response.data.response.map((filteredDataObj: any, index: any) => {
            console.log('times of trigger', index);

            for (let i = 0; i < itemArray.length; i++) {

              console.log(
                'filteredDataObj.inventoryId',
                filteredDataObj.inventoryId
              );

              if (itemArray[i] !== parseInt(filteredDataObj.inventoryId)) {
                console.log('reached once true');
                pushMe = true;
              } else {
                console.log('reached break statement');
                pushMe = false;
                break;
              }
            }

            if (pushMe) {
              console.log('times reached fbdfbdfzBf');

              furtherFilteredData.push(filteredDataObj);
            }
          });
        }
        else if (packageDetailContext?.primaryItem.length > 0) {
          let pushMe = false;
          response.data.response.map((filteredDataObj: any, index: any) => {
            console.log('times of trigger', index);

            for (let i = 0; i < packageDetailContext?.primaryItem.length; i++) {

              console.log(
                'filteredDataObj.inventoryId',
                filteredDataObj.inventoryId
              );
              console.log('packageDetailContext?.primaryItem', packageDetailContext?.primaryItem[i]);


              if (packageDetailContext?.primaryItem[i].inventoryId !== parseInt(filteredDataObj.inventoryId)) {
                console.log('reached once true', filteredDataObj);
                pushMe = true;
              } else {
                console.log('reached break statement', filteredDataObj);
                pushMe = false;
                priceFilteredData.push(filteredDataObj)
                setBucketCopy(priceFilteredData)
                break;
              }
            }

            if (pushMe) {
              console.log('times reached fbdfbdfzBf');

              furtherFilteredData.push(filteredDataObj);
            }
          });
        } else {
          console.log('reached else of sr');
          furtherFilteredData = response.data.response;
        }
        if (furtherFilteredData.length <= 0) {
          setGridData([]);
          setresultController({ ...resultControllerObj, controlRecord: true });

          // setloaderController(false);
          setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
        } else {
          // setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
          // setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
          // console.log('Response from api', response);

          // setGridData(furtherFilteredData); //This will assign the API result to get the Search Result grid get load.
          if (source == 'searchButtonNotClick') {
            //setsearchResult([...gridData, ...furtherFilteredData]);
            setGridData([...gridData, ...furtherFilteredData]);
          } else {
            //setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
            setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
            console.log('Response from api', response);

            setGridData(furtherFilteredData); //This will assign the API result to get the Search Result grid get load.
          }
        }
        //setsearchResult(response.data.response);

        // setloaderController(false);
        //setLoaderBundle({ ...loaderBundle, loaderController: false });

        console.log('Response from api', response);

        const ApiResult: any = response.data.response;
        console.log('response', ApiResult);
        console.log('response-array', ApiResult[0]);

        // if (salesLeadId !== null && storeSalesData.modelNumber == null && storeSalesData.inventories[0].inventoryNumber !== null && getSalesInfoFail == false) {
        //   console.log("passed the condition");
        //   console.log("response-array", ApiResult[0]);
        //   const apiInventoryid: any = ApiResult[0];
        //   console.log("apiInventoryid", apiInventoryid);
        //   const setInventoryid: any = apiInventoryid.inventoryId;
        //   console.log("setInventoryid", setInventoryid);
        //   let SalesInventoryId: any = [];
        //   SalesInventoryId = [...itemArray, parseInt(setInventoryid)];
        //   setBucketCopy(ApiResult);
        //   addSalesLeadItem(setInventoryid);
        //   setItemArray(SalesInventoryId);
        // } // Commented as QA Requested
        // else {
        //   setGridData(ApiResult);
        // }
        setresultController(resultControllerObj);
      } else if (response.data.response.length <= 0) {
        // setGridData([]);
        // // setcontrolRecord(true);
        // setresultController({ ...resultController, controlRecord: true });

        // // setloaderController(false);
        // setLoaderBundle({ ...loaderBundle, loaderController: false });
        // if (gridData.length == 0) {
        //   setGridData([]);
        //   setresultController({ ...resultController, controlRecord: true });
        //   setLoaderBundle({ ...loaderBundle, loaderController: false });
        // } else {
        //   sethasMore(false);
        // }

        if (source == 'searchButtonClick') {
          setGridData([]);
          setresultController({ ...resultControllerObj, controlRecord: true });
          setLoaderBundle({ ...loaderBundle, loaderController: false });
        } else if (source == 'searchButtonNotClick') {
          sethasMore(false);
          setLoaderBundle({ ...loaderBundle, loaderController: false });
        }
      }
    }
    catch {
      setresultController({
        ...resultControllerObj,
        internalServerController: true,
      });
    }
  };

  const gridCallMethod = () => {
    console.log("Came inside Grid Method");

    if (gridData !== undefined && gridData.length > 0) {
      console.log("Inside table bind", gridData);
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
            <CardContent className={classes.p0}>
              <DynamicGridComponent
                items={gridData}
                orderBy="loanerAgreement"
                handleActionType={addItem}
                order={sortDirection.ASC}
                reveiveHasMore={hasMore}
                reveivescrollLoader={scrollLoader}
                triggerSearch={searchClick}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );
    } else if (resultController.internalServerController) {
      return (
        <ExceptionController
          throwExceptionDecider="Went Wrong"
          throwStyle={classes}
        />

      );
    } else if (resultController.controlRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Records"
          throwStyle={classes}
        />

      );
    } else if (resultController.controlEmptyRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Item"
          throwStyle={classes}
        />

      );
    }
  };


  //PS-12 this method will be getting all the package items from api
  const addAllItem = async () => {
    setmodalController({ ...modalController, addAllPopUp: false });
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    setItemArray([]);
    const response = await pricingCall(
      [],
      PackageItemId,
      true,
      null,
      null,
      false,
      '',
      null
    );
    console.log("add all item popup", response);
    if (response.status == 200 && response.data.packageInformation.packagePriceInfo === null) {
      setpackageListData(response.data.packageInformation.packageTypeList);
      setIsPackage(true);
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      setInitialAgrTerm(response?.data?.agreementPricing?.agreementTerm)
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
      setreceivedpackage(response.data.itemPricing[0].packageName);
      console.log(
        "response.data.itemPricing[0].packageName",
        response.data.itemPricing[0].packageName
      );
      let newItemArray: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((value: any) => {
          console.log("individual inv id of package", value.inventoryId);
          newItemArray = [...newItemArray,
          {
            inventoryId: Number(value.inventoryId),
            inventoryPriceId: Number(value.inventoryPriceId),
          }
          ];
        });
        setfooterControlData(false);
        if (response.data.packageInformation !== null && Object.keys(response.data.packageInformation).length !== 0 &&
          response.data.packageInformation !== '') {
          setpackageCriteria(
            response.data.packageInformation.packageTypeList[0].packageCriteria
          );
          setreceivedpackage(response.data.itemPricing[0].packageName);
        } else {
          setpackageCriteria(null);
        }
      }
      setItemArray(newItemArray);
      console.log("Item Array in package", newItemArray);
      setInternalContextProvider(response.data);
      const copyTableData = gridData;
      bucketCopy.map((obj: any) => {
        copyTableData.push(obj);
      });
      setGridData(copyTableData);
      let emptyCopy: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((obj: any) => {
          gridData.map((value: any) => {
            if (value.inventoryId === obj.inventoryId) {
              emptyCopy = [...emptyCopy, value];
            }
          });
        });
        setBucketCopy(emptyCopy);
      }
      console.log("the empty copy is", emptyCopy);
      console.log("before tab data values", gridData);
      let emptyTableData: any = [];
      emptyTableData = gridData.filter((object: any) => !emptyCopy.includes(object));
      console.log("exptyTableData", emptyTableData);
      setGridData(emptyTableData);
      if (response.data !== undefined) {
        setShowAddedItem(true);
        setmodalController({ ...modalController, addAllPopUp: false });
      }
    } else if (
      response.status === 200 &&
      response.data.packageInformation.packagePriceInfo !== null
    ) {
      const modalInvPopupator: any = [];
      response.data.itemPricing.map((obj: any) => {
        modalInvPopupator.push({
          inventoryNumber: obj.inventoryNumber,
          inventoryName: obj.inventoryName,
        });
      });
      console.log('dfdvfd dcfv542 modalInvPopupator', modalInvPopupator);
      setisPackageFlow(true);
      setpackageInvPopulator(modalInvPopupator);
      setopenPackagePriceTag(true);
      setpackageBlueLabel(
        response.data.packageInformation.packagePriceInfo.packageType
      );
      if (
        response.data.packageInformation.packagePriceInfo.packagePrice[0] ===
        'ACTIVE'
      ) {
        setPackageleftValue(
          response.data.packageInformation.packagePriceInfo.packagePrice[1]
        );
        setPackagerightValue(
          response.data.packageInformation.packagePriceInfo.packagePrice[0]
        );
      } else {
        setPackageleftValue(
          response.data.packageInformation.packagePriceInfo.packagePrice[0]
        );
        setPackagerightValue(
          response.data.packageInformation.packagePriceInfo.packagePrice[1]
        );
      }
    }
    else if (response.status == 400) {
      setmodalController({
        ...modalController,
        itemPricingFail: true,
      });
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
      setPricexeptionMessage(response.data.errors[0].error == "Invalid inventoryIds" ? 'No active price tags found for selected inventory' : response.data.errors[0].error);
    }
  };

  const declineAdding = () => {
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: false,
    });
    if (itemArray.length > 0) {
      setShowAddedItem(true);
    } else {
      setShowAddedItem(false);
      setInternalContextProvider({});
    }
  }; //PS-12


  const calculateSetToDefault = () => {
    setpriceController(RateTermCompetitor);
  };

  const addItem = async (
    itemid: any,
    index: any,
    inventoryPriceId: any
  ) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });

    setcollectIndex(index);
    let constructedItemArray = [];
    if (isPackage && editPackageAry == undefined) {
      constructedItemArray = [];
    } else {
      constructedItemArray = itemArray;
    }
    let packageParameter = '';
    if (constructedItemArray.length > 0) {
      packageParameter = selectedPackage;
    }
    const packageNametoSend = editPackageAry == undefined ? packageParameter : packageDetailContext.packageName
    const response: any = await pricingCall(
      constructedItemArray,
      itemid,
      false,
      null,
      null,
      false,
      packageNametoSend,
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    console.log('raw response', response);
    console.log('Respoooooonse', response.data);
    let newItemArray: any = [];
    if (
      response.data !== undefined &&
      response.status == 200 &&
      response.data.isPackage === true
    ) {
      // setShowAddedItem(false);
      // setaddAllPopUp(true);
      setmodalController({ ...modalController, breakPopup: true });

      setPackageItemId(parseInt(itemid));
      setPackagePricingNameReq(packageNametoSend);
      setPackagePricingResponse(response);
    } else if (response.status == 200 && response.data.isPackage === false) {
      const allowPricePopulate = await pricePopulator(response);
      if (allowPricePopulate) {
        let constructedItemArray = [];
        if (isPackage && editPackageAry == undefined) {
          constructedItemArray = [];
        } else {
          constructedItemArray = itemArray;
        }

        newItemArray = [
          ...constructedItemArray,
          {
            inventoryId: Number(itemid),
            inventoryPriceId: Number(inventoryPriceId),
          },
        ];
        setinitialAgrData(response.data.agreementPricing.agreementRate);
        setInitialAgrTerm(response?.data?.agreementPricing?.agreementTerm)
        setIsPackage(false);
        console.log('newItemArray in add item', newItemArray);
        const uniqueChars = [...new Map(response.data.itemPricing.map((item: any) => [item.packagePriceId, item])).values()];
        const packageIds: any[] = []
        uniqueChars.map((value: any) => {
          if (value.packagePriceId != null && value.packagePriceId != 'null') {
            console.log('valuepackagePriceIdaddItem', value.packagePriceId)
            packageIds.push(Number(value.packagePriceId))
          }
        })
        editPackageAry ? setbreakPriceIds(packageIds) : null
        setItemArray(newItemArray);
        console.log('reached setitemArrayData');
        setpackageListData(response.data.packageInformation.packageTypeList);
        console.log('reached setpackageData');

        console.log(
          'response.data.packageInformation',
          response.data.packageInformation
        );
        setfooterControlData(false);
        if (isPackage) {
          console.log('reached expected');
          console.log('reached expected copy', bucketCopy);
          const copyTableData = gridData;
          bucketCopy.map((obj: any) => {
            copyTableData.push(obj);
          });
          setGridData(copyTableData);
        }
        if (
          response.data.packageInformation.packageTypeList != null &&
          Object.keys(response.data.packageInformation.packageTypeList)
            .length !== 0 &&
          response.data.packageInformation != ''
        ) {
          const listOfPackage = response.data.packageInformation.packageTypeList;
          const ruleForSelectedPackage = listOfPackage.filter((obj: any) => obj.packageName == packageNametoSend);
          console.log('ruleForSelectedPackage', ruleForSelectedPackage);

          if (ruleForSelectedPackage.length > 0) {
            setpackageCriteria(
              ruleForSelectedPackage[0].packageCriteria
            );
          }
          else {
            setpackageCriteria(listOfPackage[0].packageCriteria);
          }
          setreceivedpackage(response.data.itemPricing[0].packageName);

          setselectedPackage(response.data.itemPricing[0].packageName);
        } else if (selectedPackage !== '') {
          setselectedPackage(selectedPackage);
        } else {
          setpackageCriteria(null);
          setselectedPackage('');

        }
        setInternalContextProvider(response.data);
        setBucketCopy([...bucketCopy, gridData[index]]);
        console.log('copy', bucketCopy);
        console.log('tabledata.filter', gridData);
        const newTableData = gridData.filter((item: any, filterIndex: any) => {
          return filterIndex != index;
        });
        if (newTableData.length <= 0) {
          // setcontrolEmptyRecord(true);
          setresultController({
            ...resultControllerObj,
            controlEmptyRecord: true,
          });
        }


        console.log('ItemMainAry', newTableData);
        setGridData(newTableData);
        setShowAddedItem(true);
      }
    } else if (response.status == 400) {
      // setpackageExceptionController(true);
      setmodalController({
        ...modalController,
        itemPricingFail: true,
      });
      if (response.data.errors[0].error == 'Invalid inventoryIds') {
        setPricexeptionMessage(
          'No active price tags found for selected inventory'
        );
      } else {
        setPricexeptionMessage(response.data.errors[0].error);
      }
    }

  };

  const pricePopulator = (response: any) => {
    let allowPopulatePrice = false;
    for (let i = 0; i < response.data.inventoryPriceInfo.length; i++) {
      if (response.data.inventoryPriceInfo[i].inventoryPrice.length > 1) {
        allowPopulatePrice = false;
        break;
      } else {
        allowPopulatePrice = true;
      }
    }

    if (allowPopulatePrice) {
      return allowPopulatePrice;
    } else {
      //const arrayCollection: any = [];
      const itemArrayCollection: any = [];
      for (let i = 0; i < response.data.inventoryPriceInfo.length; i++) {
        if (response.data.inventoryPriceInfo[i].inventoryPrice.length > 1) {
          // arrayCollection.push(
          //   response.priceTag[i].inventoryPriceTags[0],
          //   response.priceTag[i].inventoryPriceTags[1]
          // );
          settagBlueLabel({
            inventoryNumber:
              response.data.inventoryPriceInfo[i].inventoryNumber,
            inventoryDesc: response.data.inventoryPriceInfo[i].inventoryDesc,
            inventoryId: response.data.inventoryPriceInfo[i].inventoryId,
          });
          if (
            response.data.inventoryPriceInfo[i].inventoryPrice[0]
              .inventoryPriceType == 'ACTIVE'
          ) {
            setleftValue(response.data.inventoryPriceInfo[i].inventoryPrice[0]);
            setrightValue(
              response.data.inventoryPriceInfo[i].inventoryPrice[1]
            );
          } else {
            setleftValue(response.data.inventoryPriceInfo[i].inventoryPrice[1]);
            setrightValue(
              response.data.inventoryPriceInfo[i].inventoryPrice[0]
            );
          }
        } else {
          itemArrayCollection.push({
            inventoryId: Number(
              response.data.inventoryPriceInfo[i].inventoryId
            ),
            inventoryPriceId: Number(
              response.data.inventoryPriceInfo[i].inventoryPrice[0]
                .inventoryPriceId
            ),
          });
        }
      }
      setOpenPriceTag(true);
      setnewSingleInvPricingInvArray(itemArrayCollection); // this should be stored in a different array
      //setstoreArrayCollection(arrayCollection);
      //dynamicPopupHandler(arrayCollection);
      //console.log('vfdcdddc12d52c', arrayCollection);
    }
  };
  const pricingCallTrigger = async (packData: any) => {
    if (itemArray.length > 1) {
      setLoaderBundle({ ...loaderBundle, masterLoader: true });
      const getResult = await pricingCall(
        itemArray,
        null,
        false,
        priceController.AgrRateInp != '' && priceController.AgrRateInp != null ? parseFloat(priceController.AgrRateInp) : null,
        priceController.AgrTermInp != '' && priceController.AgrTermInp != null ? parseInt(priceController.AgrTermInp) : null,
        priceController.CompetitorCheck,
        packData,
        null
      );

      if (getResult.data !== undefined &&
        getResult.status == 200) {
        setLoaderBundle({ ...loaderBundle, masterLoader: false });
        setInternalContextProvider(getResult.data);
        setmodalController({ ...modalController, addAllPopUp: false });
        if (getResult?.data?.agreementPricing) {
          setinitialAgrData(getResult?.data?.agreementPricing?.agreementRate);
          setInitialAgrTerm(getResult?.data?.agreementPricing?.agreementTerm)
        }

        if (getResult.data.packageInformation !== null) {
          setreceivedpackage(getResult.data.itemPricing[0].packageName);
        }
        setShowAddedItem(true);
        const listOfPackage = getResult.data.packageInformation.packageTypeList;
        const ruleForSelectedPackage = listOfPackage.filter((obj: any) => obj.packageName == packData);
        setpackageCriteria(
          ruleForSelectedPackage[0].packageCriteria
        );
      } else if (getResult.status == 400) {
        setmodalController({
          ...modalController,
          itemPricingFail: true,
        });
        setLoaderBundle({ ...loaderBundle, masterLoader: false });
        setPricexeptionMessage(getResult.data.errors[0].error == "Invalid inventoryIds" ? 'No active price tags found for selected inventory' : getResult.data.errors[0].error);
      }
      return getResult;
    }
  };

  const closeRateTermPopUp = () => {
    setmodalController({ ...modalController, RateTermController: false });
    setpricingException("");

  };

  const closepackageExceptionController = () => {
    setmodalController({
      ...modalController,
      packageExceptionController: false,
    });
  };

  const acceptPackageCriteria = () => {
    setmodalController({ ...modalController, PackageExceptionPopup: false });
  };

  const closeAcceptPricingFail = () => {
    setmodalController({ ...modalController, itemPricingFail: false });
  }
  const closeGroupPop = async () => {
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: false,
    });
    const allowPricePopulate = await pricePopulator(PackagePricingResponse);
    if (allowPricePopulate) {
      setreceivedpackage("");
      const newItemArray: any = [];
      console.log("Package Pricing Response", PackagePricingResponse.data);
      if (itemArray.length > 0) {
        console.log('reached muli map');

        PackagePricingResponse.data.itemPricing.map(
          (packageMap: any, indexPak: any) => {
            console.log(
              'mapper of parseInt(packageMap.inventoryId)',
              parseInt(packageMap.inventoryId)
            );

            console.log('times reached here jhbvd');
            newItemArray.push({
              inventoryId: Number(packageMap.inventoryId),
              inventoryPriceId: Number(packageMap.inventoryPriceId),
            });
          }
        );
      }

      if (itemArray.length == 0) {
        console.log('reached singlr map');
        newItemArray.push({
          inventoryId: Number(
            PackagePricingResponse.data.itemPricing[
              PackagePricingResponse.data.itemPricing.length - 1
            ].inventoryId
          ),
          inventoryPriceId: Number(
            PackagePricingResponse.data.itemPricing[
              PackagePricingResponse.data.itemPricing.length - 1
            ].inventoryPriceId
          ),
        });
      }

      if (newItemArray.length > 1) {
        setpackageListData(PackagePricingResponse.data.packageInformation.packageTypeList);
        setreceivedpackage(PackagePricingResponse.data.itemPricing[0].packageName);

        if (PackagePricingNameReq) {
          const listOfPackage = PackagePricingResponse.data.packageInformation.packageTypeList;
          const ruleForSelectedPackage = listOfPackage.filter((obj: any) => obj.packageName == PackagePricingNameReq);
          setpackageCriteria(
            ruleForSelectedPackage[0].packageCriteria
          );
        }
        else {
          setpackageCriteria(
            PackagePricingResponse.data.packageInformation.packageTypeList[0].packageCriteria
          );
        }
        setInternalContextProvider(PackagePricingResponse.data);
      }
      else {
        PackagePricingResponse.data.itemPricing[0].packageName = ""
        setInternalContextProvider(PackagePricingResponse.data);
      }
      setItemArray(newItemArray);
      setinitialAgrData(PackagePricingResponse.data.agreementPricing.agreementRate);
      setInitialAgrTerm(PackagePricingResponse?.data?.agreementPricing?.agreementTerm)

      setIsPackage(false);
      console.log("Break Item Array", newItemArray);
      console.log("itemArray in closeGroupPop", itemArray);
      console.log(
        "PackagePricingResponse.itemPricing.inventoryId",
        PackagePricingResponse.data.itemPricing[0].inventoryId
      );
      setfooterControlData(false);
      setBucketCopy([...bucketCopy, gridData[collectIndex]]);
      console.log("copy", bucketCopy);
      const uniqueChars = [...new Map(PackagePricingResponse.data.itemPricing.map((item: any) => [item.packagePriceId, item])).values()];
      // const packageIds = uniqueChars.filter((value: any) => {
      //   if (value.packagePriceId != 'null') {
      //     console.log('value', value.packagePriceId)
      //     return parseInt(value.packagePriceId)
      //   }
      // })
      const packageIds: any[] = []
      uniqueChars.map((value: any) => {
        if (value.packagePriceId != null && value.packagePriceId != 'null') {
          console.log('valuepackagePriceIdbreak', value.packagePriceId)
          packageIds.push(Number(value.packagePriceId))
        }
      })
      setbreakPriceIds(packageIds)
      console.log("received PackageItemId", PackageItemId);
      const newTableData = gridData.filter((item: any) => {
        if (parseInt(item.inventoryId) == PackageItemId) {
          console.log("PackageItemId in logic added", PackageItemId);
          console.log("item.inventoryid in logic added", item.inventoryid);
          console.log("Logic Worked");
        }
        console.log("filter item", item);
        return parseInt(item.inventoryId) != PackageItemId;
      });
      if (newTableData.length <= 0) {
        setresultController({ ...resultControllerObj, controlEmptyRecord: true });
      }
      console.log("Add Package newTableData", newTableData);
      setGridData(newTableData);
      // if (PackagePricingResponse.data !== undefined) {
      //   setShowAddedItem(true);
      //   setmodalController({ ...modalController, addAllPopUp: false });
      // }
      // console.log("ItemMainAry", newTableData);
      // setmodalController({
      //   ...modalController,
      //   addAllPopUp: false,
      //   breakPopup: false,
      // });
      setShowAddedItem(true);
    }
  };


  // this method is used to close the group popup //PS-12
  const declineAddOther = () => {
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: true,
    });
  };

  const deleteSingleItem = async (itemId: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    console.log("Required Item Id", itemId);
    setdeleteId({ ...deleteId, invDeleteId: itemId });
  };

  const receiveAgreementRate = (value: any) => {
    setpriceController({ ...priceController, AgrRateInp: value });
  };
  const receiveAgreementTerm = (value: any) => {
    setpriceController({ ...priceController, AgrTermInp: value });
  };


  const storeSelectedPackage = (value: any) => {
    console.log('value of the selected packjage', value);
    setselectedPackage(value);
  }
  /*
  * calls
  * This printPackagePriceTag method will generate HTML format and Print Format.
  * */
  const printPackagePriceTag = () => {

    const packagePriceTagDoc = document?.getElementById('PackagePriceTagList')?.innerHTML;
    if (packagePriceTagDoc !== undefined && packagePriceTagDoc !== null) {
      const a: any = window.open(
        "",
        '_blank'

      );
      a?.document?.write("<html>");
      a?.document?.write(packagePriceTagDoc);
      a?.document?.write("</html>");
      a?.document?.close();
      a?.print();
    }

  }

  /*
   * Method
   * This handlePrintPackageOnClick method will call break,create and print Packages API call and error msg method.
   * */
  const handlePrintPackageOnClick = (value: boolean) => {

    if (internalContextData !== undefined && value) {
      const req1 = async () => {
        setPrint(true)
        if (breakPriceIds?.length > 0 && breakPriceIds !== null) {

          const brIds = breakPriceIds.map((value: any) => {
            if (breakPriceIds !== null && value != undefined && value !== null) {
              return parseInt(value)
            }
          })
          const req = {
            packagePriceIds: brIds
          }
          const response: any = await postbreakPackage(req);
          if (response?.status == 200) {
            const res = {
              packageInformation: {
                packageName: selectedPackage ? selectedPackage : internalContextData.itemPricing[0].packageName,
                packageRate: parseFloat(internalContextData.agreementPricing.agreementRate),
                packageTerm: parseInt(internalContextData.agreementPricing.agreementTerm)
              },
              inventoryInfo: internalContextData.itemPricing.map((el: any) => (
                {
                  inventoryNumber: el.inventoryNumber,
                  inventoryPriceId: Number(el.inventoryPriceId)
                }
              )),
              storeNumber: sessionStorage.getItem('storeNumber')
            }
            const packageres = await createPackage(res)
            if (packageres?.status == 200) {
              const packageId = packageres?.data?.packagePriceId
              setprintCreatePackage([packageId])
              const respData = await getPackagePriceTag({ packagePriceIds: [parseInt(packageId)] });
              setPrint(false)
              if (respData?.status == 200) {
                setPackagePriceTags(respData?.data)
                printPackagePriceTag();
                history.push('/inventory/packagepricetag')
              }
              else if (respData?.status == 400) {
                seterrorMsgCondition(true)
                seterrorContent(packageres?.data?.errors[0]?.error)
              }
              else {
                setErrorMsg(true)
              }
            }
            else if (packageres?.status == 400) {
              seterrorMsgCondition(true)
              seterrorContent(packageres?.data?.errors[0]?.error)
              setPrint(false)
            }
            else {
              setErrorMsg(true)
            }
          }
          else if (response?.status == 400) {
            seterrorMsgCondition(true)
            seterrorContent(response?.data?.errors[0]?.error)
            setPrint(false)
          }
          else {
            setErrorMsg(true)
          }
        }

        else {
          const data = internalContextData.itemPricing.map((value: any) => {
            if (value.packagePriceId == editPackageAry) {
              return true
            }
          });
          console.log('editscnerioooooo', data);
          const res = {
            packageInformation: {
              packageName: selectedPackage ? selectedPackage : internalContextData.itemPricing[0].packageName,
              packageRate: parseFloat(internalContextData.agreementPricing.agreementRate),
              packageTerm: parseInt(internalContextData.agreementPricing.agreementTerm),
              packagePriceId: editPackageAry != undefined && data[0] ? Number(editPackageAry) : null,

            },
            inventoryInfo: internalContextData.itemPricing.map((el: any) => (
              {
                inventoryNumber: el.inventoryNumber,
                inventoryPriceId: Number(el.inventoryPriceId)
              }
            )),
            storeNumber: sessionStorage.getItem('storeNumber')
          }
          const packageres = await createPackage(res)
          if (packageres?.status == 200) {
            const packageId = packageres?.data?.packagePriceId
            setprintCreatePackage([packageId])
            const respData = await getPackagePriceTag({ packagePriceIds: [parseInt(packageId)] });
            setPrint(false)
            if (respData?.status == 200) {
              setPackagePriceTags(respData?.data)
              printPackagePriceTag();
              history.push('/inventory/packagepricetag')
            }
            else if (respData?.status == 400) {
              seterrorMsgCondition(true)
              seterrorContent(packageres?.data?.errors[0]?.error)
              setPrint(false)
            }
            else {
              setErrorMsg(true)
            }
          }
          else if (packageres?.status == 400) {
            seterrorMsgCondition(true)
            seterrorContent(packageres?.data?.errors[0]?.error)
            setPrint(false)
          }
          else {
            setErrorMsg(true)
          }
        }
      }
      req1()
    }
  }

  /*
   * calls
   * This RACModalCard materialUI Popup calls.
   * */
  const methodAssigner: any = {
    closeGroupPopCaller: closeGroupPop,
    acceptBreakPackageCaller: acceptBreakPackage,
    declineBreakPackageCaller: declineBreakPackage,
    closepackageExceptionControllerCaller: closepackageExceptionController,
    addAllItemCaller: addAllItem,
    closeRateTermPopUpCaller: closeRateTermPopUp,
    declineAddOtherCaller: declineAddOther,
    declineAddingCaller: declineAdding,
    acceptPackageCriteriaCaller: acceptPackageCriteria,
    closeModalCaller: closeModal,
    acceptPricingFailCaller: closeAcceptPricingFail,
  };

  const noRecordPopup = () => {
    return (
      <Grid>
        <Grid>
          <Grid className={classes.textCenter}>
            <AlertIcon />
            <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
              {' '}
              Something Went Wrong.
            </Typography>

          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => setErrorMsg(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const errorMessagePopup = () => {
    return (
      <Grid>
        <Grid>
          <Grid className={classes.textCenter}>
            <AlertIcon />
            <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
              {' '}
              {errorContent}
            </Typography>

          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => seterrorMsgCondition(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const buildPackageSelection = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
            Please select a Package to Continue{' '}
            {/* <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              {receivedpackage}
            </Typography> */}
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setopenPackageSelection(false)}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const buildPackageOneItemValidation = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography className={`${classes.fontSemiBold} ${classes.fs6}`}>
            Package requires more than one item.No Package will be Created{' '}
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setsingleItemvalidate(false);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };



  const tagSingleInvCaller = async (selectedTag?: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    console.log('vfdsdd cvfd', newSingleInvPricingInvArray);

    let arrayForm: any;
    if (selectedTag === undefined) {
      arrayForm = newSingleInvPricingInvArray;
    } else {
      arrayForm = [...itemArray, selectedTag];
    }
    console.log('fds wedfd 65454', arrayForm);
    console.log('vfdsx sdcvfd777', selectedTag);
    let packageParameter = '';
    if (arrayForm.length > 0) {
      packageParameter = selectedPackage;
    }
    const response: any = await pricingCall(
      //packageDetailContext.primaryItem.length == 0 ? arrayForm : packageDetailContext.primaryItem,
      arrayForm,
      null,
      false,
      null,
      null,
      false,
      packageParameter,
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    if (response.status === 200) {
      const collectPriceArray: any = [];
      response.data.itemPricing.map((obj: any, index: any) => {
        collectPriceArray.push({
          inventoryId: Number(obj.inventoryId),
          inventoryPriceId: Number(obj.inventoryPriceId),
        });
      });
      setItemArray(collectPriceArray);
      const responseHolder = response.data.itemPricing;
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      setInitialAgrTerm(response?.data?.agreementPricing?.agreementTerm);

      setIsPackage(false);
      setpackageListData(response.data.packageInformation.packageTypeList);
      setfooterControlData(false);
      if (isPackage) {
        console.log('reached expected');
        console.log('reached expected copy', bucketCopy);
        const copyTableData = gridData;
        bucketCopy.map((obj: any) => {
          copyTableData.push(obj);
        });
        setGridData(copyTableData);
      }

      if (
        response.data.packageInformation.packageTypeList !== null &&
        // response.data.packageInformation !== {} &&
        response.data.packageInformation.packageTypeList !== ''
      ) {
        if (packageParameter) {
          const listOfPackage = response.data.packageInformation.packageTypeList;
          const ruleForSelectedPackage = listOfPackage.filter((obj: any) => obj.packageName == packageParameter);
          setpackageCriteria(
            ruleForSelectedPackage[0].packageCriteria
          );
        }
        else {
          setpackageCriteria(response.data.packageInformation.packageTypeList[0].packageCriteria);
        }
        setreceivedpackage(response.data.itemPricing[0].packageName);
        setselectedPackage(response.data.itemPricing[0].packageName);
      } else if (selectedPackage !== '') {
        setselectedPackage(selectedPackage);
      } else {
        setpackageCriteria(null);
        setselectedPackage('');
      }
      setInternalContextProvider(response.data);
      setBucketCopy([...bucketCopy, gridData[collectIndex]]);
      const newTableData = gridData.filter((item: any, filterIndex: any) => {
        return filterIndex != collectIndex;
      });
      if (newTableData.length <= 0) {
        // setcontrolEmptyRecord(true);
        setresultController({
          ...resultControllerObj,
          controlEmptyRecord: true,
        });
      }


      console.log('ItemMainAry', newTableData);
      setGridData(newTableData);
      // setaddAllPopUp(false);
      //setmodalController({ ...modalController, addAllPopUp: false });

      setShowAddedItem(true);
    }
  };

  const tagPackageInvCaller = async (decider: any) => {
    setopenPackagePriceTag(false);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    const response = await pricingCall(
      packageDetailContext.primaryItem.length == 0 ? [] : packageDetailContext.primaryItem,
      packageDetailContext.primaryItem.length == 0 ? PackageItemId : null,
      true,
      null,
      null,
      false,
      '',
      decider
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    if (response.status == 200) {
      setselectedPackagePriceTag(decider);
      setisPackageFlow(true);
      setpackageListData(response.data.packageInformation.packageTypeList);
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      setInitialAgrTerm(response?.data?.agreementPricing?.agreementTerm)
      setreceivedpackage(response.data.itemPricing[0].packageName);

      let newItemArray: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((value: any) => {
          console.log('individual inv id of package', value.inventoryId);

          newItemArray = [
            ...newItemArray,
            {
              inventoryId: Number(value.inventoryId),
              inventoryPriceId: Number(value.inventoryPriceId),
            },
          ];
        });

        setfooterControlData(false);

        if (
          response.data.packageInformation.packagePriceInfo !== null &&
          Object.keys(response.data.packageInformation.packagePriceInfo)
            .length !== 0 &&
          response.data.packageInformation.packagePriceInfo !== ''
        ) {
          setpackageCriteria(
            response.data.packageInformation.packageTypeList[0].packageCriteria
          );
          setreceivedpackage(response.data.itemPricing[0].packageName);

        } else {
          setpackageCriteria(null);
        }
      }
      setItemArray(newItemArray);
      // setitemArrayContext(newItemArray);

      setInternalContextProvider(response.data);

      // setGridData([...tabledata, copy]);
      const copyTableData = gridData;

      // setGridData([...tabledata, obj]);
      bucketCopy.map((obj: any) => {
        copyTableData.push(obj);
      });
      setGridData(copyTableData);
      // setcopy([]);

      let emptyCopy: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((obj: any) => {
          gridData.map((value: any) => {
            if (value.inventoryId === obj.inventoryId) {
              emptyCopy = [...emptyCopy, value];
            }
          });
        });
        setBucketCopy(emptyCopy);

      }
      console.log('the empty copy is', emptyCopy);
      console.log('before tab data values', gridData);
      let emptyTableData: any = [];
      emptyTableData = gridData.filter((object: any) => !emptyCopy.includes(object));
      console.log('exptyTableData', emptyTableData);
      setGridData(emptyTableData);
      if (response.data !== undefined) {

        setShowAddedItem(true);
        // setaddAllPopUp(false);
        setmodalController({ ...modalController, addAllPopUp: false });
      }
    } else if (response.status == 400) {
      // setpackageExceptionController(true);
      setmodalController({
        ...modalController,
        itemPricingFail: true,
      });
      setPricexeptionMessage(response.data.errors[0].error);
      // setmasterLoader(false);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    }
  };
  const invQuantityFormation = (payload: any) => {
    const ResponseArr: any = [];
    for (let i = 0; i < payload.length; i++) {
      const indexOfSameObj =
        ResponseArr.findIndex((checkObj: any) => (
          (checkObj.rmsDepartment == payload[i].rmsDepartment) &&
          (checkObj.rmsSubDepartment == payload[i].rmsSubDepartment) &&
          (checkObj.rmsBracket == payload[i].rmsBracket) &&
          (checkObj.rmsItemMasterId == payload[i].rmsItemMasterId)));
      if (ResponseArr.length == 0 || indexOfSameObj == -1) {
        ResponseArr.push(payload[i])
      }
      else {
        ResponseArr[indexOfSameObj].quantity =
          ResponseArr[indexOfSameObj].quantity + payload[i].quantity;
      }
    }
    return ResponseArr
  }

  const validateReqWithMaxCriteriaFn = (req: any, Criteria: any) => {
    const criteriaCheck: any = Criteria.map((obj: any) => {
      obj.validateMaxQuantity = obj.maxQuantity;
      return obj
    });

    for (let i = 0; i < criteriaCheck.length; i++) {
      for (let j = 0; j < req.length; j++) {
        if (
          (((criteriaCheck[i].rmsDepartment == req[j].rmsDepartment)) || !criteriaCheck[i].rmsDepartment) &&
          ((criteriaCheck[i].rmsSubDepartment == req[j].rmsSubDepartment) || !criteriaCheck[i].rmsSubDepartment) &&
          ((criteriaCheck[i].rmsBracket == req[j].rmsBracket) || !criteriaCheck[i].rmsBracket) &&
          ((criteriaCheck[i].rmsItemMasterId == req[j].rmsItemMasterId) || !criteriaCheck[i].rmsItemMasterId)
        ) {
          criteriaCheck[i].validateMaxQuantity = criteriaCheck[i].validateMaxQuantity - req[j].quantity;
        }

      }

    }

    console.log("Max validation failed", criteriaCheck.filter((obj: any) => obj.validateMaxQuantity < 0))
    return criteriaCheck.filter((obj: any) => obj.validateMaxQuantity < 0)


  }

  const validateReqWithCriteriaFn = (req: any, Criteria?: any, maxCriteria?: any) => {

    const validateReqWithMinCriteriaFn = (req: any, Criteria: any) => {
      const criteriaCheck: any = Criteria.map((obj: any) => {
        obj.validateMinQuantity = obj.minQuantity;
        return obj
      });

      for (let i = 0; i < criteriaCheck.length; i++) {
        for (let j = 0; j < req.length; j++) {
          if (
            (((criteriaCheck[i].rmsDepartment == req[j].rmsDepartment)) || !criteriaCheck[i].rmsDepartment) &&
            ((criteriaCheck[i].rmsSubDepartment == req[j].rmsSubDepartment) || !criteriaCheck[i].rmsSubDepartment) &&
            ((criteriaCheck[i].rmsBracket == req[j].rmsBracket) || !criteriaCheck[i].rmsBracket) &&
            ((criteriaCheck[i].rmsItemMasterId == req[j].rmsItemMasterId) || !criteriaCheck[i].rmsItemMasterId)
          ) {
            criteriaCheck[i].validateMinQuantity = criteriaCheck[i].validateMinQuantity - req[j].quantity;
          }

        }

      }
      const minQuanityRes = criteriaCheck.filter((obj: any) => obj.validateMinQuantity > 0)
      if (minQuanityRes.length > 0) {
        console.log("Minqunaity failed", minQuanityRes)
        return minQuanityRes
      }
      else {
        console.log("Min qty passed", minQuanityRes)

        return validateReqWithMaxCriteriaFn(req, maxCriteria);

      }

    }
    return validateReqWithMinCriteriaFn(req, Criteria);
  }



  const validateItems = (cartItems: any, criteria: any) => {
    debugger
    criteria.map((x: any) => {
      x.originalMinQuantity = x.minQuantity;
      x.originalMaxQuantity = x.maxQuantity;
    })
    for (let cartItem of cartItems) {

      for (let criterion of criteria) {
        /* This nested if will assign a match level for the criteria, 1 being the best match and 
         * 4 being the least match
         * Note: Using an if-else won't make sure the sub department exists within that department */
        if (cartItem.rmsDepartment == criterion.rmsDepartment) {
          if (cartItem.rmsSubDepartment == criterion.rmsSubDepartment) {
            if (cartItem.rmsBracket == criterion.rmsBracket) {
              if (cartItem.rmsItemMasterId == criterion.rmsItemMasterId) {
                criterion.matchLevel = 1;
              }
              else if (criterion.rmsItemMasterId == null) {
                criterion.matchLevel = 2;
              }
              else {
                criterion.matchLevel = 6;
              }
            }
            else if (criterion.rmsBracket == null) {
              criterion.matchLevel = 3
            }
            else {
              criterion.matchLevel = 6;
            }
          }
          else if (criterion.rmsSubDepartment == null) {
            criterion.matchLevel = 4
          }
          else {
            criterion.matchLevel = 6;
          }
        }
        else {
          criterion.matchLevel = 6;
        }
      }

      criteria.sort((a: any, b: any) => a.matchLevel - b.matchLevel);

      // console.log("criteria",criteria)

      const matchedIndex: any = criteria.findIndex((x: { maxQuantity: any; matchLevel: any; }) => x.maxQuantity > 0 && x.matchLevel != 6);
      if (matchedIndex > -1) {
        criteria[matchedIndex].minQuantity -= cartItem.quantity; // min quantity should stay negative or 0
        criteria[matchedIndex].maxQuantity -= cartItem.quantity; // max quantity should stay positive or 0
      }
      else {
        criteria[0].minQuantity -= cartItem.quantity;
        criteria[0].maxQuantity -= cartItem.quantity;
      }
    }

    return criteria.filter((x: { minQuantity: any; maxQuantity: any; }) => x.minQuantity > 0 || x.maxQuantity < 0)
  }


  const minMaxValidator = () => {
    console.log(bucketCopy);
    const invDetails: any = [];
    bucketCopy.forEach((obj: any) => {
      //checking items in filtered array
      //item array is empty so we are pushing that item
      invDetails.push(
        {
          rmsDepartment: obj.departmentsEn,
          rmsSubDepartment: obj.subDepartmentsEn,
          rmsBracket: obj.bracketEn,
          rmsItemMasterId: obj.rmsItemMasterId,
          quantity: 1
        }
      );
    });
    console.log('invDetails', invDetails)
    const selectedInvArr = invQuantityFormation(invDetails);
    console.log('selectedInvArr', selectedInvArr);
    console.log('packageCriteria', packageCriteria);
    let maxQtyPackageCriteria: any = JSON.parse(JSON.stringify(packageCriteria))
    const validPackageCriteria = packageCriteria?.filter((obj: any) => obj.minQuantity > 0);
    console.log('validPackageCriteria', validPackageCriteria);
    const invalidMinMaxObjArr: any = validateItems(selectedInvArr, maxQtyPackageCriteria);
    console.log('invalidMinMaxObjArr', invalidMinMaxObjArr);
    if (invalidMinMaxObjArr?.length == 0) {
      OnPrintPrice('Rent Ready')
    } else {
      console.log('trigger error popup');
      minMaxException(invalidMinMaxObjArr);
    }
  };

  const minMaxException = (index: any) => {
    console.log('index log of fault package', index);
    if (packageCriteria !== undefined) {
      const wordingArray: any = [
        'Package missing required component(s).No package will be created.',
      ];
      index.forEach((obj: any) => {
        const wordFormation = `${obj.rmsDepartment} Minimum: ${obj.originalMinQuantity} Maximum: ${obj.originalMaxQuantity}`;
        wordingArray.push(wordFormation);
      })
      console.log('wordingArray finally', wordingArray);

      if (wordingArray !== undefined && wordingArray.length > 0) {
        let str: any = '';
        wordingArray.map((el: any) => {
          str = str + el;
        });
        console.log('str formed value', str);

        setformException(str);
      }
      setmodalController({ ...modalController, PackageExceptionPopup: true });
    }
  };
  const mainPrint = () => {
    console.log('receivedpackage', receivedpackage);
    console.log('selectedPackage', selectedPackage);
    if (isPackage === false && internalContextData.itemPricing.length > 1 && (packageDropdownValidate == "" && receivedpackage == "")) {
      setopenPackageSelection(true);
    }
    else if (isPackage === false && internalContextData.itemPricing.length == 1) {
      setsingleItemvalidate(true)
    }
    else {
      minMaxValidator()
      //OnPrintPrice('Rent Ready')
    }
  }

  const SLACancel = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
    setOpenPriceTag(true);
  };
  const closeSLA = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
    if (isPackageFlow) {
      setopenPackagePriceTag(true);
    } else {
      setOpenPriceTag(true);
    }
  };
  const SLACompleted = (value: any) => {
    setSecondFactorEnabled(false);
    if (isPackageFlow) {
      tagPackageInvCaller('PRTD');
      // alert('tagPackageCaller');
    } else {
      tagSingleInvCaller();
    }
  };
  const triggerNextModal = (inventoryId: any, inventoryPriceId: any, decider: any) => {
    console.log('sdfbvfdfv inventoryId sd', inventoryId);
    console.log('sdfdedcx inventoryPriceId bvc', inventoryPriceId);

    setnewSingleInvPricingInvArray([
      ...newSingleInvPricingInvArray,
      {
        inventoryId: Number(inventoryId),
        inventoryPriceId: Number(inventoryPriceId),
      },
    ]);

    // setnewSingleInvPricingInvArray({
    //   inventoryId: Number(inventoryId),
    //   inventoryPriceId: Number(inventoryPriceId),
    // });
    setOpenPriceTag(false);
    if (decider === 'Printed') {
      setSecondFactorEnabled(true);
    } else if (decider === 'Active') {
      tagSingleInvCaller({
        inventoryId: Number(inventoryId),
        inventoryPriceId: Number(inventoryPriceId),
      });
    }
  };
  const triggerPackageNextModal = (decider: any) => {
    console.log('ASDCVD decider sd', decider);

    setopenPackagePriceTag(false);
    if (decider === 'PRTD') {
      setSecondFactorEnabled(true);
    } else if (decider === 'ACTIVE') {
      alert('Packege should be called');
    }
  };

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const openPriceModal = () => {
    return (
      <Grid className="">
        <Card
          className={`${classes.racSkyBlue} ${classes.bluecards} `}
          style={{ padding: '10px 10px', backgroundColor: '#EFF4FF' }}
        >
          <Typography
            style={{
              float: 'left',
              marginRight: '48px',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
          >
            Inventory # : {tagBlueLabel.inventoryNumber}
          </Typography>
          <Typography
            style={{
              float: 'left',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
            title={
              tagBlueLabel?.inventoryDesc?.length > 22
                ? tagBlueLabel?.inventoryDesc
                : ''
            }
          >
            Description : {truncString(tagBlueLabel.inventoryDesc, 22, '...')}
          </Typography>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              New Price Tag (Active Pricing)
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '13px',
                }}
              >
                ${leftValue.tagRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {leftValue.tagTerm}
              </Typography>
            </Grid>
            {featureFlagDetails && (Object.keys(featureFlagDetails)).length > 1 && featureFlagDetails[ADD_ON_PRICING] == '1' ? <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Add-On Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {leftValue.tagAddOnRate ? `$${leftValue.tagAddOnRate}` : '-'}
              </Typography>
            </Grid> : null}
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${leftValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '30px',
                }}
              >
                ${leftValue.tagTotal}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() =>
                  triggerNextModal(
                    tagBlueLabel.inventoryId,
                    leftValue.inventoryPriceId,
                    'Active'
                  )
                }
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
          {/* <Divider
            orientation="vertical"
            style={{
              position: 'absolute',
              height: '260px',
              right: '320px',
              top: '125px',
              marginTop: '15px',
            }}
          ></Divider> */}
          {/* <hr style={{backgroundColor:"red",transform: 'rotate(90deg)',margin:'inherit'}}></hr> */}
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              Printed Price Tag
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '13px',
                }}
              >
                ${rightValue.tagRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {rightValue.tagTerm}
              </Typography>
            </Grid>
            {featureFlagDetails && (Object.keys(featureFlagDetails)).length > 1 && featureFlagDetails[ADD_ON_PRICING] == '1' ? <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Add-On Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {rightValue.tagAddOnRate ? `$${rightValue.tagAddOnRate}` : '-'}
              </Typography>
            </Grid> : null}
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${rightValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '30px',
                }}
              >
                ${rightValue.tagTotal}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() =>
                  triggerNextModal(
                    tagBlueLabel.inventoryId,
                    rightValue.inventoryPriceId,
                    'Printed'
                  )
                }
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const dynamicInvNumberBinding = () => {
    return packageInvPopulator.map((obj: any, index: any) => {
      return (
        <Typography
          key={index}
          variant="caption"
          className={`${classes.priceTagLabelColour} ${classes.w100} ${classes.floatLeft} ${classes.margincardsize}`}
        >
          {obj.inventoryNumber}
        </Typography>
      );
    });
  };

  const dynamicInvDescriptionBinding = () => {
    return packageInvPopulator.map((obj: any, index: any) => {
      return (
        <Typography
          key={index}
          variant="caption"
          className={`${classes.priceTagLabelColour} ${classes.w100} ${classes.floatLeft} ${classes.margincardsize}`}
        >
          {obj.inventoryName}
        </Typography>
      );
    });
  };

  const openPackagePriceModal = () => {
    return (
      <Grid className="">
        <Card
          className={`${classes.racSkyBlue} ${classes.bluecards} `}
          style={{ padding: '10px 10px', backgroundColor: '#EFF4FF' }}
        >
          <Typography
            style={{
              float: 'left',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
            title={
              leftValue?.inventoryName?.length > 22
                ? leftValue?.inventoryName
                : ''
            }
          >
            Package Type : {packageBlueLabel}
          </Typography>
        </Card>
        <Card
          className={`${classes.racSkyBlue} ${classes.bluecards} ${classes.mt3}`}
          style={{
            padding: '10px 10px',
            backgroundColor: '#EFF4FF',
            maxHeight: '130px',
            overflow: 'auto',
          }}
        >
          <Grid className={`${classes.floatLeft}`} md={4}>
            <Typography
              variant="caption"
              style={{
                float: 'left',
                color: '#2279fd',
                fontSize: '15px',
                fontFamily: 'opensans-bold',
                width: '100%',
                backgroundColor: '#EFF4FF',
                zIndex: '1',
                top: '-10px',
                position: 'sticky',
              }}
            >
              Inventory # :{' '}
            </Typography>
            {dynamicInvNumberBinding()}
          </Grid>

          <Grid className={`${classes.floatLeft}`} md={8}>
            <Typography
              variant="caption"
              style={{
                float: 'left',
                color: '#2279fd',
                fontSize: '15px',
                fontFamily: 'opensans-bold',
                width: '100%',
                backgroundColor: '#EFF4FF',
                zIndex: '1',
                top: '-10px',
                position: 'sticky',
              }}
            //className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
            >
              Description :{' '}
            </Typography>
            {dynamicInvDescriptionBinding()}
          </Grid>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              New Price Tag (Active Pricing)
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Agreement Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${PackageleftValue?.agreementRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Agreement Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                {PackageleftValue.agreementTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '70px',
                }}
              >
                ${PackageleftValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '70px',
                }}
              >
                ${PackageleftValue.totalCost}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() => tagPackageInvCaller('ACTIVE')}
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
          {/* <Divider
            orientation="vertical"
            style={{
              position: 'absolute',
              height: '260px',
              right: '320px',
              top: '260px',
              marginTop: '15px',
            }}
          ></Divider> */}
          {/* <hr style={{backgroundColor:"red",transform: 'rotate(90deg)',margin:'inherit'}}></hr> */}
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              Printed Price Tag
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Agreement Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${PackagerightValue?.agreementRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Agreement Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                {PackagerightValue.agreementTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '70px',
                }}
              >
                ${PackagerightValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '70px',
                }}
              >
                ${PackagerightValue.totalCost}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() => triggerPackageNextModal('PRTD')}
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.agreementContainerSpacing}>
      <div>
        {loaderBundle.masterLoader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        {print === true ? (
          <Grid
            style={{
              position: 'fixed',
              background: '#f7f5f5',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              opacity: 0.6,
              zIndex: 2000,
              textAlign: 'center',
              margin: '0px 0px',
            }}
          >
            <Grid
              style={{
                display: 'block',
                position: 'fixed',
                zIndex: 9999999,
                top: '40%',
                right: '50%',
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        {secondLvlAuth ? (
          <TwoFactorComponent
            setTwoFactorCancelClick={() => setsecondLvlAuth(false)}
            setTwoFactorCompleted={handlePrintPackageOnClick}
            moduleName={moduleName}
            CurrentRole={currentCWRole}
          ></TwoFactorComponent>
        ) : null}
        {SecondFactorEnabled === true ? (
          <TwoFactorComponent
            // setTwoFactorCancelClick={setTwoFactorCancelClick}
            setTwoFactorCancelClick={SLACancel}
            closeSLAClicked={closeSLA}
            // setTwoFactorCompleted={setTwoFactorCompleted}
            setTwoFactorCompleted={SLACompleted}
            // setRoleFromSLAModule={provideSLAData}
            moduleName={ModuleName}
            CurrentRole={currentCWRole}
            // minRole={SLAMinRole}
            throwCurrentPage="ItemSearch"
          />
        ) : null}
        <Grid container spacing={2} className={dynamicViewClass}>
          <Grid item className={altersearch}>
            <SearchFilter
              searchClick={searchClick}
              IsPackage={true}
              setItem={setItemArray}
              PackagedropdownOnClick={PackagedropdownOnClick}
              triggerItemPriceByData={pricingCallTrigger}
              throwStyle={classes}
              throwSalesLead={salesLeadData}
              throwPackage={receivedpackage}
              throwSelectedPackage={storeSelectedPackage}
              throwItemArray={itemArray}
              throwpackageList={packageList}
            />
            <div>
              {loaderBundle.loaderController === true &&
                loaderBundle.Gridloader === true ? (
                <ExceptionController
                  throwExceptionDecider="Data Delay"
                  throwStyle={classes}
                />

              ) : (
                gridCallMethod()
              )}
            </div>
            <ModalContainer
              throwModalController={modalController}
              throwMethodAssigner={methodAssigner}
              throwPricingException={pricingException}
              throwFormException={formException}
              throwPriceExceptionMessage={PricexeptionMessage}
            />
          </Grid>
          {showAddedItem ? (
            <Usercontext.Provider value={internalContextData}>
              <AddedItem
                triggeroneDelete={deleteSingleItem}
                setreceiveAgreementRate={receiveAgreementRate}
                setreceiveAgreementTerm={receiveAgreementTerm}
                module="Agreement"
                throwPackage={receivedpackage}
                priceexceptionMessage={pricingException}
                triggerSetToDefault={calculateSetToDefault}
                throwStyle={classes}
                selectedPackage={selectedPackage}
              />
            </Usercontext.Provider>
          ) : null}
        </Grid>
        <Grid className={classes.fixedBottom} style={{ display: 'block' }}>
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.ms2}
              onClick={() => { history.push({ pathname: `/inventory/packagepricetag` }) }}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={classes.floatRight}>
            <RACButton
              color='primary'
              variant="contained"
              // disabled={packagebtn}
              // disabled={print}
              disabled={internalContextData?.itemPricing?.length > 0 ? false : true}
              // loading={print}
              onClick={() => mainPrint()}
              style={{ marginRight: '4px' }}
            >
              {packageText ? 'Print Price Tag' : 'Save & Print Price Tag'}
            </RACButton>
          </Grid>
        </Grid>
        <RACModalCard
          isOpen={errorMsg}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={"9px !important"}

        >

          {noRecordPopup()}

        </RACModalCard>
        <RACModalCard
          isOpen={errorMsgCondition}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={"9px !important"}

        >

          {errorMessagePopup()}

        </RACModalCard>
        <RACModalCard
          isOpen={openPackageSelection}
          maxWidth="xs"
          borderRadius={'25px !important'}
          //eslint-disable-next-line react/no-children-prop
          children={buildPackageSelection()}
        ></RACModalCard>
        <RACModalCard
          isOpen={singleItemvalidate}
          maxWidth="xs"
          borderRadius={'25px !important'}
          //eslint-disable-next-line react/no-children-prop
          children={buildPackageOneItemValidation()}
        ></RACModalCard>
        <RACModalCard
          isOpen={openPriceTag}
          maxWidth={'sm'}
          borderRadius={'25px !important'}
          title={'Please choose the price'}
          closeIcon={true}
          onClose={() => setOpenPriceTag(false)}
          //eslint-disable-next-line react/no-children-prop
          children={openPriceModal()}
        ></RACModalCard>

        <RACModalCard
          isOpen={openPackagePriceTag}
          maxWidth={'sm'}
          borderRadius={'25px !important'}
          title={'Please choose the price'}
          closeIcon={true}
          onClose={() => setopenPackagePriceTag(false)}
          //eslint-disable-next-line react/no-children-prop
          children={openPackagePriceModal()}
        ></RACModalCard>
        <div id="PackagePriceTagList" style={{ display: 'none' }}>
          <PrintPackagePrice
            packageIdsToPrint={printCreatePackage}
            detailsToPrint={packagePriceTags}
            language={"English"}
          />
        </div>

      </div>
    </div>
  );
};

export default ItemSearchResult;