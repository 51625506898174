/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@rentacenter/racstrap';
import { searchCriteriaStyles } from '../../componentstyles/searchCriteriaStyles';
import { getSearchParams, getPackageDropdown } from '../../../api/user';
import { SearchInventoryObj } from './packageItemSearchProps';
import { PackagePriceContext } from '../../../context/PackagePriceTagContext';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-btn.svg';

export type Componentprops = {
  inventoryId?: number;
  searchClick: (
    txtValue: SearchInventoryObj['itemObject'],
    source: any
  ) => void;
  IsPackage?: boolean;
  PackagedropdownOnClick: (value: any) => void;
  triggerItemPriceByData: (event: any) => Promise<any>;
  throwStyle: any;
  throwSalesLead: any;
  throwPackage: any;
  throwSelectedPackage: (event: any) => void;
  throwItemArray: any;
  throwpackageList: any;
  setItem: any;
};
/* eslint-disable sonarjs/cognitive-complexity */
export default function SearchFilter(props: Componentprops) {
  const classes = props.throwStyle;
  const searchCriteriaClassName = searchCriteriaStyles();
  const salesLeadProps = props.throwSalesLead;
  const {
    setsearchCriteria,
    packageContextValue: [packageDetailContext],
  } = useContext(PackagePriceContext);
  const [controlViewMode, setcontrolViewMode] = useState(false);
  const [PackageDropValuesToBind, setPackageDropValuesToBind] = useState<any>();
  const [conditionAry, setconditionAry] = useState<any>([]);
  const [packageTypeAry, setpackageTypeAry] = useState<any>([]);
  const [departmentAry, setdepartmentAry] = useState<any>([]);
  const [subdepartmentAry, setsubdepartmentAry] = useState<any>([]);
  const [conditionLoader, setconditionLoader] = useState<boolean>(true);
  const [packageconditionLoader, setPackageconditionLoader] =
    useState<boolean>(true);
  const [packageErrorMessage, setpackageErrorMessage] = useState('');
  const initialTxtValues: SearchInventoryObj['itemObject'] = {
    itemNo: '',
    serialNo: '',
    bracket: '',
    brand: '',
    model: '',
    poNo: 0,
    locationTypeVal: 'Store',
    locationval: '986',
    statusId: 1,
    subStatusId: 2,
    conditionId: null,
    departments: '',
    subDepartments: '',
    packageTypeval: '',
    locationNumber: '',
    currentStoreNumber: '',
  };

  const [TxtValues, setTxtValues] =
    useState<SearchInventoryObj['itemObject']>(initialTxtValues);

  const [packagedrpdwn, setpackagedrpdwn] = useState(false);
  const [searchbtndisable, setsearchbtndisable] = useState(true);
  const [clrbtndisable, setclrbtndisable] = useState(false);
  const [packPerDiscount, setpackPerDiscount] = useState<any>('');
  const [packDollarDiscount, setpackDollarDiscount] = useState<any>('');
  const [packagePriceContext, setpackagePriceContext] = useState<any>();

  useEffect(() => {
    if (salesLeadProps !== undefined) {
      console.log('tiggered the salesLead', salesLeadProps);
      setTxtValues({
        ...TxtValues,
        itemNo: salesLeadProps.itemNo,
        model: salesLeadProps.model,
      });
    }
  }, [salesLeadProps]);

  useEffect(() => {
    console.log('packagePriceTagcriteriA', packageDetailContext);
    if (packageDetailContext.packageName !== '') {
      props.searchClick(
        {
          ...TxtValues,
          packageTypeval: packageDetailContext.packageName,
        },
        'searchButtonClick'
      );
      console.log('packageIN', packageDetailContext);
    }
  }, [packageDetailContext]);

  useEffect(() => {
    if (packageTypeAry !== undefined && packageTypeAry.length > 0) {
      props.PackagedropdownOnClick(TxtValues.packageTypeval);
      packageTypeAry.map((value: any) => {
        if (
          TxtValues.packageTypeval == value.packageName ||
          packageDetailContext?.packageName == value.packageName
        ) {
          // eslint-disable-next-line no-console
          console.log('Came inside package type if');

          setpackPerDiscount(value.pctDiscountOnRate);
          setpackDollarDiscount(value.dollarDiscountOnRate);
        }
        setpackagedrpdwn(true);
      });
      /* eslint-disable no-console */
      console.log('packPerDiscount' + packPerDiscount);
      /* eslint-disable no-console */
      console.log('packDollarDiscount' + packDollarDiscount);
    }
    if (TxtValues.packageTypeval !== '') {
      setpackagedrpdwn(true);
    } else {
      setpackagedrpdwn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TxtValues.packageTypeval]);

  useEffect(() => {
    console.log('reached package rebind useeffect', props.throwpackageList);
    if (
      props.throwpackageList !== undefined &&
      props.throwpackageList !== null
    ) {
      setPackageconditionLoader(true);
      console.log('reached package rebind');
      const buildPackage = props.throwpackageList;
      console.log('buildPackage data', buildPackage);
      const setterPackage = [
        { packagePriceId: '0', packageName: 'Select' },
        ...buildPackage,
      ];
      setpackageTypeAry(setterPackage);
      console.log('expexted TxtValues', TxtValues);

      if (props.throwPackage !== null && props.throwPackage !== undefined) {
        setTxtValues({
          ...TxtValues,
          packageTypeval: props.throwPackage,
        });
        setpackPerDiscount(props.throwpackageList[0].pctDiscountOnRate);
        setpackDollarDiscount(props.throwpackageList[0].dollarDiscountOnRate);
        setpackagedrpdwn(true);
      }
      setPackageconditionLoader(false);
    } else if (
      props.throwpackageList === null ||
      props.throwpackageList === undefined
    ) {
      console.log('reached package original bind');
      const getPackage_APIcall = async () => {
        const currentStore = sessionStorage.getItem('storeNumber');
        const packageReq: any = {
          storeNumber: currentStore,
        };
        let packageresponse: any = [];
        if (PackageDropValuesToBind == undefined) {
          setPackageconditionLoader(true);

          packageresponse = await getPackageDropdown(packageReq);
          setPackageDropValuesToBind(packageresponse);
          if (packageDetailContext) {
            setTxtValues({
              ...TxtValues,
              packageTypeval: packageDetailContext.packageName,
            });
          }

          setPackageconditionLoader(false);
        } else if (PackageDropValuesToBind !== undefined) {
          packageresponse = PackageDropValuesToBind;
        }

        if (
          packageresponse !== undefined &&
          packageresponse.length !== undefined
        ) {
          const buildPackage = packageresponse;
          const setterPackage = [
            { packagePriceId: '0', packageName: 'Select' },
            ...buildPackage,
          ];
          setpackageTypeAry(setterPackage);
          if (setterPackage.length === 1) {
            setpackageErrorMessage('No package type found');
          }
          console.log('expexted TxtValues', TxtValues);
        } else {
          const setterPackage = [
            { packagePriceId: '0', packageName: 'Select' },
          ];
          setpackageTypeAry(setterPackage);
          if (packageresponse.status == 200) {
            setpackageErrorMessage('No package type found');
          } else if (
            packageresponse.status == 500 ||
            packageresponse.status == 400
          ) {
            setpackageErrorMessage('Unable to fetch data');
          }
          setPackageconditionLoader(false);
        }
      };
      getPackage_APIcall();
    }
  }, [props.throwpackageList, props.throwPackage]);

  useEffect(() => {
    /* eslint-disable no-console */
    console.log('session' + TxtValues);
    const currentStore = sessionStorage.getItem('storeNumber');
    setTxtValues({ ...TxtValues, locationNumber: currentStore });
    const getSearchParams_APIcall = async () => {
      setconditionLoader(true);
      const response: any = await getSearchParams();
      console.log('response on the all dd', response);

      setconditionLoader(false);
      if (response !== undefined && response !== null) {
        const buildConditionOption = response.conditionList;
        const setterCondition = [
          { id: '0', referenceCode: 'ALL' },
          ...buildConditionOption,
        ];
        console.log('setterCondition', setterCondition);

        setconditionAry(setterCondition);

        const buildDepartOption = response.departmentList;
        const setterDepartment = [
          { id: '0', description: 'Select' },
          ...buildDepartOption,
        ];
        setdepartmentAry(setterDepartment);

        const buildSubDepartOption = response.subDepartmentList;
        const setterSubDepartment = [
          { id: '0', description: 'Select' },
          ...buildSubDepartOption,
        ];
        setsubdepartmentAry(setterSubDepartment);
      } else {
        const setterDepartment = [{ id: '0', description: 'Select' }];
        const setterSubDepartment = [{ id: '0', description: 'Select' }];
        const setterCondition = [{ id: '0', referenceCode: 'Select' }];
        setconditionAry(setterCondition);
        setdepartmentAry(setterDepartment);
        setsubdepartmentAry(setterSubDepartment);
      }
    };
    getSearchParams_APIcall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      TxtValues.bracket?.length > 2 ||
      TxtValues.brand?.length > 0 ||
      TxtValues.model?.length > 2 ||
      TxtValues.itemNo?.length > 2 ||
      TxtValues.serialNo?.length > 2 ||
      TxtValues.conditionId !== null ||
      TxtValues.departments !== '' ||
      TxtValues.subDepartments !== '' ||
      TxtValues.packageTypeval !== ''
    ) {
      setsearchbtndisable(false);
      setclrbtndisable(true);
    } else {
      setsearchbtndisable(true);
      setclrbtndisable(false);
    }
    if (TxtValues.packageTypeval !== '') {
      setpackagedrpdwn(true);
    } else {
      setpackagedrpdwn(false);
    }
    console.log('TxtValues is updated as', TxtValues);
  }, [TxtValues]);

  const dynamicViewClass = controlViewMode ? classes.disableme : 'enableme';

  const EnterOnClick = (e: any) => {
    if (
      e.key == 'Enter' &&
      (TxtValues.bracket?.length > 2 ||
        TxtValues.brand?.length > 2 ||
        TxtValues.model?.length > 2 ||
        TxtValues.itemNo?.length > 2 ||
        TxtValues.serialNo?.length > 2 ||
        TxtValues.conditionId !== null ||
        TxtValues.departments !== '' ||
        TxtValues.subDepartments !== '' ||
        TxtValues.packageTypeval !== '')
    ) {
      SearchOnclick();
    }
  };

  const clearbtn = () => {
    const currentStore = sessionStorage.getItem('storeNumber');
    setTxtValues({
      ...TxtValues,
      itemNo: '',
      serialNo: '',
      bracket: '',
      brand: '',
      model: '',
      poNo: 0,
      locationTypeVal: 'Store',
      locationval: '986',
      statusId: 1,
      subStatusId: 2,
      departments: '',
      subDepartments: '',
      packageTypeval: '',
      locationNumber: currentStore,
      currentStoreNumber: '',
      rateDiscount: 9.99,
      totalCashPriceDiscount: 0.0,
      conditionId: null,
    });
    setclrbtndisable(false);
    setsearchbtndisable(true);
    props.throwSelectedPackage('');
  };
  /*
   * RF No: IS_PC_32
   * This method is passing the values to the ApiLayer to get the filtered data from database.
   * */
  const SearchOnclick = () => {
    (document.getElementById('a11y_Item') as HTMLInputElement).blur();
    (document.getElementById('a11y_bracket') as HTMLInputElement).blur();
    (document.getElementById('a11y_Model') as HTMLInputElement).blur();
    (document.getElementById('a11y_Brand') as HTMLInputElement).blur();
    (document.getElementById('a11y_Serial') as HTMLInputElement).blur();
    props.searchClick(TxtValues, 'searchButtonClick');
    setsearchCriteria(TxtValues);
    /* eslint-disable no-console */
    console.log(TxtValues);
    props.throwSelectedPackage(TxtValues.packageTypeval);
  };

  const packageChange = async (e: any) => {
    if (e.target.value !== 'Select') {
      if (props.throwItemArray.length > 1) {
        const apiResponse: any = await props.triggerItemPriceByData(
          e.target.value
        );
        console.log('apiResponse checking log test log', apiResponse);
        if (apiResponse.status == 200) {
          console.log('successful item call');
          setTxtValues({
            ...TxtValues,
            packageTypeval: e.target.value,
          });
          props.throwSelectedPackage(e.target.value);
        } else {
          if (e.target.value == 'Select') {
            setTxtValues({ ...TxtValues, packageTypeval: '' });
          } else {
            setTxtValues({ ...TxtValues, packageTypeval: e.target.value });
          }
        }
      } else {
        setTxtValues({ ...TxtValues, packageTypeval: e.target.value });
      }
    } else {
      setTxtValues({ ...TxtValues, packageTypeval: '' });
      props.throwSelectedPackage('');
    }
  };

  const optionsArrayFunction = () => {
    if (departmentAry !== undefined) {
      return departmentAry.map((entry: any) => {
        return { label: entry.description, value: entry.description };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const conditionArrayFunction = () => {
    if (conditionAry !== undefined) {
      return conditionAry.map((value: any, index: any) => {
        console.log('Counting log', index);
        let displayvalue: any;
        if (index >= 0) {
          if (value.referenceCode == 'EXCP') {
            displayvalue = 'Exception';
          } else if (value.referenceCode == 'ATO') {
            displayvalue = 'Auto';
          } else if (value.referenceCode == 'NEW') {
            displayvalue = 'New';
          } else if (value.referenceCode == 'ALL') {
            displayvalue = 'All';
          } else if (value.referenceCode == 'Select') {
            displayvalue = 'Select';
          }
          return { label: displayvalue, value: value.id.toString() };
        }
      });
    } else {
      return [{ label: 'All', value: '0' }];
    }
  };

  const subDepartmentArrayFunction = () => {
    if (subdepartmentAry !== undefined) {
      return subdepartmentAry.map((entry: any) => {
        return { label: entry.description, value: entry.description };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const packageArrayFunction = () => {
    if (packageTypeAry !== undefined && packageTypeAry !== null) {
      return packageTypeAry.map((obj: any) => {
        return { label: obj.packageName, value: obj.packageName };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const handleOnChangeTxtbox = (e: any) => {
    const FieldName = e.target.name;
    const InputValue = e.target.value;

    setTxtValues({ ...TxtValues, [FieldName]: InputValue });
  };

  const handle_OnChangeTxtNumber = (e: any, number: any) => {
    if (e.target.value == '' || number.test(e.target.value)) {
      setTxtValues({ ...TxtValues, [e.target.name]: e.target.value });
    }
  };
  const handleOnChangeItemTxtboxOnlyNumber = (e: any) => {
    if (e.target.id == 'a11y_Item') {
      const number = /^[0-9\b]+$/;
      handle_OnChangeTxtNumber(e, number);
    } else if (e.target.id == 'a11y_Serial') {
      setTxtValues({ ...TxtValues, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className={dynamicViewClass}>
      <Grid container className={classes.mt2}>
        <Grid item md={12}>
          {props.IsPackage !== false ? (
            <Typography className={classes.title} variant="h6">
              Search Criteria
            </Typography>
          ) : (
            <Typography className={classes.title} variant="h5">
              Package Item Search
            </Typography>
          )}
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Item #"
                    inputLabelClassname={classes.formLabel}
                    id="a11y_Item"
                    type="number"
                    name="itemNo"
                    isCurrency={false}
                    digitFormat="normal"
                    maxlength={13}
                    value={TxtValues.itemNo}
                    OnChange={(e) => handleOnChangeItemTxtboxOnlyNumber(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Department"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={optionsArrayFunction()}
                    classes={{
                      nativeSelect: searchCriteriaClassName.dropdownBold,
                    }}
                    defaultValue={
                      TxtValues.departments !== ''
                        ? TxtValues.departments
                        : 'Select'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.value == 'Select'
                        ? setTxtValues({
                            ...TxtValues,
                            departments: '',
                          })
                        : setTxtValues({
                            ...TxtValues,
                            departments: e.target.value,
                          });
                    }}
                    {...(departmentAry.length == 1 &&
                      !conditionLoader && {
                        errorMessage: 'Unable to fetch Data',
                      })}
                  />
                </Grid>
                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Sub Department"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={subDepartmentArrayFunction()}
                    classes={{
                      nativeSelect: searchCriteriaClassName.dropdownBold,
                    }}
                    defaultValue={
                      TxtValues.subDepartments !== ''
                        ? TxtValues.subDepartments
                        : 'Select'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.value == 'Select'
                        ? setTxtValues({
                            ...TxtValues,
                            subDepartments: '',
                          })
                        : setTxtValues({
                            ...TxtValues,
                            subDepartments: e.target.value,
                          });
                    }}
                    {...(subdepartmentAry.length == 1 &&
                      !conditionLoader && {
                        errorMessage: 'Unable to fetch Data',
                      })}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Bracket"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_bracket"
                    name="bracket"
                    isCurrency={false}
                    value={TxtValues.bracket}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Model #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Model"
                    name="model"
                    isCurrency={false}
                    value={TxtValues.model}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Brand"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Brand"
                    name="brand"
                    isCurrency={false}
                    value={TxtValues.brand}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Serial #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Serial"
                    name="serialNo"
                    isCurrency={false}
                    value={TxtValues.serialNo}
                    OnChange={(e) => handleOnChangeItemTxtboxOnlyNumber(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Condition"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={conditionArrayFunction()}
                    classes={{
                      nativeSelect: searchCriteriaClassName.dropdownBold,
                    }}
                    defaultValue={
                      TxtValues.conditionId !== null
                        ? TxtValues.conditionId
                        : '0'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setTxtValues({
                        ...TxtValues,
                        conditionId: e.target.value.toString(),
                      });
                    }}
                    {...(conditionAry.length == 1 &&
                      !conditionLoader && {
                        errorMessage: 'Unable to fetch Data',
                      })}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.mt2}>
                {props.IsPackage === true ? (
                  <>
                    <Grid item md={3}>
                      <RACSelect
                        inputLabel="Package Type"
                        inputLabelClassName={
                          searchCriteriaClassName.dropDownLabelAlign
                        }
                        loading={packageconditionLoader}
                        options={packageArrayFunction()}
                        classes={{
                          nativeSelect: searchCriteriaClassName.dropdownBold,
                        }}
                        defaultValue={
                          TxtValues.packageTypeval !== ''
                            ? TxtValues.packageTypeval
                            : 'Select'
                        }
                        onChange={(e) => packageChange(e)}
                        {...(packageTypeAry.length == 1 &&
                          !packageconditionLoader && {
                            errorMessage: packageErrorMessage,
                          })}
                      />
                    </Grid>

                    <Grid
                      item
                      md={8}
                      lg={5}
                      className={searchCriteriaClassName.responcol6}
                    >
                      {packagedrpdwn === true ? (
                        <Typography
                          variant="caption"
                          className={searchCriteriaClassName.packageStructure}
                        >
                          <Typography variant="caption">
                            <Typography
                              variant="caption"
                              className={classes.fontSemiBold}
                            >
                              Rate Discount :{' '}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.fontBold}
                            >
                              {' '}
                              {packPerDiscount !== null
                                ? packPerDiscount + '%'
                                : '$' + packDollarDiscount}
                            </Typography>
                          </Typography>

                          <Typography variant="caption">
                            <Typography
                              variant="caption"
                              className={`${classes.ms4} ${classes.fontSemiBold}`}
                            >
                              Total Cash Price Discount :{' '}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.fontBold}
                            >
                              {' '}
                              {/* {TxtValues
                                ? TxtValues.totalCashPriceDiscount
                                : 0.0} */}
                              0.0%
                            </Typography>
                          </Typography>
                        </Typography>
                      ) : null}
                    </Grid>

                    <Grid
                      item
                      md={12}
                      lg={4}
                      className={searchCriteriaClassName.responcol12}
                    >
                      <Grid className={`${classes.floatRight} ${classes.mt4}`}>
                        {clrbtndisable ? (
                          <a
                            className={`${classes.racpadLink} ${classes.mt2} ${classes.me4}`}
                            data-testid="ClearBtn"
                            onClick={clearbtn}
                          >
                            Clear
                          </a>
                        ) : null}{' '}
                        <RACButton
                          variant="contained"
                          color="primary"
                          className={searchCriteriaClassName.searchBtn}
                          size="large"
                          onClick={() => SearchOnclick()}
                          disabled={searchbtndisable}
                        >
                          <SearchIcon className={classes.me1} />
                          Search
                        </RACButton>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
